import plan from "./plan"

/**
 * The users subscription plan
 * @typedef {Object} Plan
 * @property {string} name - Plan name
 * @property {number} price - Monthly subscription cost
 * @property {number} direct_mail - Direct Mail per note cost
 * @property {number} on_demand - On Demand per print cost
 * @property {number} on_demand_included - On Demand prints included in the plan for free
 * @property {number | null} billing_threshold - Billing threshold, null if no billing threshold is set
 * @property {Date} start_date - Start date of the billing cycle
 * @property {Date} end_date - End date of the billing cycle
 */

const actions = plan.actions
const state = plan.state
const mutations = plan.mutations
const getters = plan.getters

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
}
