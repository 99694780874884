<template>
  <div>
    <b-form-select
      data-hj-whitelist
      placeholder="Country"
      :multiple="multiple"
      v-model="country"
      :options="countries"
      @input="update()"
      :size="size ? size : 'md'"
    ></b-form-select>
  </div>
</template>

<script>
import { countriesList } from "@/lib/countries"

const countries = []
countries.push({
  value: null,
  text: "Select Country",
  disabled: true,
})

for (const country in countriesList) {
  countries.push({
    value: country,
    text: `${country} - ${countriesList[country]}`,
  })
}

export default {
  name: "CountrySelect",
  props: ["value", "multiple", "size"],
  data() {
    return {
      countries: countries,
      country: this.value,
    }
  },
  watch: {
    value: function (val) {
      if (!val) {
        return
      }

      this.country = val
    },
  },
  methods: {
    update() {
      this.$emit("input", this.country)
    },
  },
}
</script>

<style scoped></style>
