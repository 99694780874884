import Vue from "vue"
import Vuex from "vuex"
import createLogger from "vuex/dist/logger"
import { vuexfireMutations } from "vuexfire"

// import the auto exporter
import modules from "./modules"

const debug = process.env.NODE_ENV !== "production"

Vue.use(Vuex)

export default new Vuex.Store({
  modules,
  mutations: {
    //   Add other global mutations here
    ...vuexfireMutations,
  },
  strict: debug,
  plugins: debug ? [createLogger()] : [], // set logger only for development
})
