import _config from "../config.json"

let env = process.env.NODE_ENV

if (env === "test" || env === "local-firestore") {
  env = "local" // Use local env when doing testing
}
const config = _config[env || "development"]

export default config
