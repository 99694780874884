const _ = require("underscore")
const {countriesList} = require("./countries")

var seed = 0

var ILLEGAL_CHARACTERS = {
  "’": "'",
  "‘": "'",
  "،": ",",
  "‐": "-",
  "‒": "-",
  "–": "-",
  "—": "-",
  "―": "-",
  一: "-",
  "-": "-",
  " ": "-",
  "“": '"',
  "”": '"',
  "/": "/",
  "⧸": "/",
  "⁄": "/",
  "᠎": "",
  " ": " ",
  " ": " ",
  " ": " ",
  " ": " ",
  " ": " ",
  " ": " ",
  " ": " ",
  " ": " ",
  " ": " ",
  " ": " ",
  " ": " ",
  " ": " ",
  "": "",
  " ": " ",
  " ": " ",
  "　": " ",
  "﻿": "",
  "\xc2\xa0": " ",
  "\xe2\x80\x88": " ",
  "\xe2\x80\x8B": " ",
  "…": "...",
  "′": "'",
  "″": "''",
  "‴": "'''",
  "‬": "",
  "\x20\x2c": "",
}

var TEXT_TEMPLATES = {
  fundraising: `Dear {{{first name}}}\n\nWe are looking to raise (amount) but we can't do it without generous donations from companies such as yours!\n\nBy sponsoring us, you will receive (offer incentive), and with your help, we hope to make this year one of our best yet. Thank you for your time and we hope to hear from you soon.\n\nSincerely, (organisation)`,
  event_invitation: `Dear {{{first name}}}\n\n(The event) will be taking place on (date) at (location) and we would love for you to attend!\n\nCome enjoy a (day/evening) of (what can be expected) and make some memories you won't forget. Contact (number) to RSVP. We look forward to seeing you there!\n\nSincerely,\n     (organisation)`,
  customer_service_apology: `Dear {{{first name}}}\n\nWe would like to apologise for (reason). This is not typically the way that we like to do business and you deserve much better from us.\n\nOur customers are very important to us and we deeply care about your experiences.\nIf there is anything we can do to make it up to you please let us know at (number)).\n\nSincerely, (organisation)`,
  birthday: `Dear {{{first name}}},\n\nWe would just like to say: Happy Birthday!\n\nThis special day should be all about you and we hope that it brings you many happy memories. We'll be thinking of you today, wishing you the very best.\n\nLots of love,\n     All of us at (organisation)`,
  new_product: `Dear {{{first name}}}\n\nWe'd like to say thank you for staying with us all this time! To show you just how much you mean to us, here is (an exclusive early look at our new product/a discount). We think you'll love what we have to offer in the future, so be sure to keep sticking with us.\n\nYours,\n     (organisation)`,
  customer_loyalty: `Dear {{{first name}}}\n\nThis is a letter to let you know that we really appreciate having you as a customer. We hope you love what we have to offer, and if there is anything that would help improve your experience, then please let us know. We are always looking for new to please our customers.\n\nKindest regards,\n     (organisation)`,
  customer_re_engagement: `Dear {{{first name}}}\n\nWe haven't heard from you for a while. A lot has changed since you've been gone and we've got a lot of new stuff that we think you'd love to see. Here's (discount/other incentive) on us, so please get in touch and let us know you're alright!\n\nAll the best,\n     (organisation)`,
  lead_generation: `Dear {{{first name}}}\n\n(Open with what your company does)\n\nWe think you'll love what we have to offer.\n\nPlease feel free to reach out to us to see just what we can do to help your business. We'd love to give you a free consultation.\n\nKind regards,\n     (organisation)`,
  meeting_follow_up: `Dear {{{first name}}}\n\nThank you for taking the time to meet with us. It was great to finally speak with you in person and we appreciate the ideas that you brought to the table. We hope that you enjoyed the discussion just as much as we did and if there is anything else we can do for you please don't hesitate to ask.\n\nKind regards, (organisation)`,
}

var SAMPLE_ADDRESS = {
  GB: {
    title: "Mr",
    "first name": "John",
    "last name": "Doe",
    department: "Marketing",
    company: "Company",
    "address line 1": "1 Alfred Place",
    "address line 2": "Fitzrovia",
    "address line 3": "",
    city: "London",
    region: "London",
    postal_code: "TW11 9PB",
    country: "United Kingdom",
    "custom 1": "Custom 1",
    "custom 2": "Custom 2",
    "custom 3": "Custom 3",
    "custom 4": "Custom 4",
    "custom 5": "Custom 5",
    "custom 6": "Custom 6",
  },
  US: {
    title: "Mr",
    "first name": "John",
    "last name": "Doe",
    department: "Marketing",
    company: "Company",
    "address line 1": "2295  Lena Lane",
    "address line 2": "",
    "address line 3": "",
    city: "Jackson",
    state: "Mississippi",
    postal_code: "39201",
    country: "United States of America",
    "custom 1": "Custom 1",
    "custom 2": "Custom 2",
    "custom 3": "Custom 3",
    "custom 4": "Custom 4",
    "custom 5": "Custom 5",
    "custom 6": "Custom 6",
  },
  CA: {
    title: "Mr",
    "first name": "John",
    "last name": "Doe",
    department: "Marketing",
    company: "Company",
    "address line 1": "3434 rue Levy",
    "address line 2": "",
    "address line 3": "",
    city: "Montreal",
    state: "Quebec",
    postal_code: "H3C 5K4",
    country: "Canada",
    "custom 1": "Custom 1",
    "custom 2": "Custom 2",
    "custom 3": "Custom 3",
    "custom 4": "Custom 4",
    "custom 5": "Custom 5",
    "custom 6": "Custom 6",
  },
  DE: {
    title: "",
    "first name": "Max",
    "last name": "Mustermann",
    department: "Marketingabteilung",
    company: "Unternehmen",
    "address line 1": "Oldesloer Strasse 14",
    "address line 2": "",
    "address line 3": "",
    city: "Stützerbach",
    state: "Freistaat Thüringen",
    postal_code: "98714",
    country: "Germany",
    "custom 1": "Custom 1",
    "custom 2": "Custom 2",
    "custom 3": "Custom 3",
    "custom 4": "Custom 4",
    "custom 5": "Custom 5",
    "custom 6": "Custom 6",
  },
}

function remove_illigal_characters(text) {
  var clean_text = ""

  if (!text) {
    return clean_text
  }

  for (var i in text) {
    if (ILLEGAL_CHARACTERS[text[i]] !== undefined) {
      clean_text = clean_text + ILLEGAL_CHARACTERS[text[i]]
    } else {
      clean_text = clean_text + text[i]
    }
  }

  // Remove multiple spaces, leading spaces
  // clean_text = clean_text.replace(/ +(?= )/g, '')
  clean_text = clean_text.replace(/ ,/g, ",") //Space before a comma replaced by a comma
  clean_text = clean_text.replace(/ \./g, ".") //Spaces defore a "." replaced by a "."
  clean_text = clean_text.replace(/,+/g, ",") // Multiple commas replaced by a  single comma
  clean_text = clean_text.replace(/([^\.])(\.\.)(\s)/g, "$1.$3")
  clean_text = clean_text.replace(/\r\n|\r|\n/g, "\n") // Carraigae return and new line
  clean_text = clean_text.replace(/\s+$/, "") //Multiple space characters including tab, linebreaks

  return clean_text
}

/** 
 * Convert a string to title case.
 * 
 * This will always lowercase 'and' 'or' and 'of'
 * 
 * @param {string} str String to convert to title 
 * @returns {string} Title cased string
 */
const to_title_case = (str) => {
  const matching_regex = /(?:^|\s|\ |\-)(?!(and|or|of|Mc|O'|FAO))(\w*)/g
  return str.replace(matching_regex, function (match) {
    const firstNonWhiteSpaceIndex = match.length - ltrim(match).length
    return (
      match.slice(0, firstNonWhiteSpaceIndex) +
      match.charAt(firstNonWhiteSpaceIndex).toUpperCase() +
      match.slice(firstNonWhiteSpaceIndex + 1)
    )
  })
}

function ltrim(str) {
  if (!str) return str
  return str.replace(/(\s|\ |\-)/g, '')
}

function template_text(
  template,
  variables,
  include_country_name,
  country,
  is_return = false
) {
  if (!variables) {
    if (!country || !SAMPLE_ADDRESS[country]) {
      variables = SAMPLE_ADDRESS["GB"]
    } else {
      variables = SAMPLE_ADDRESS[country]
    }
  }

  var address = ""
  if (is_return && country !== "US" && country !== "CA") {
    address += `Return Address\n`
  }
  if (variables["title"]) address += `${variables["title"]} `
  if (variables["first name"])
    address += `${to_title_case(variables["first name"]).trim()} `
  if (variables["last name"])
    address += `${to_title_case(variables["last name"]).trim()}\n`
  else if (variables["first name"]) address += `\n`
  if (variables["department"]) address += `${variables["department"]}\n`
  if (variables["company"]) address += `${variables["company"]}\n`
  if (variables["country"] && ["DE"].indexOf(variables["country"]) > -1) {
    if (variables["address line 1"])
      address += `${to_title_case(variables["address line 1"]).trim()}\n`
    if (variables["address line 2"])
      address += `${to_title_case(variables["address line 2"]).trim()}\n`
  } else {
    if (variables["address line 1"])
      address += `${to_title_case(variables["address line 1"]).trim()}\n`
    if (variables["address line 2"])
      address += `${to_title_case(variables["address line 2"]).trim()}\n`
    if (variables["address line 3"])
      address += `${to_title_case(variables["address line 3"]).trim()}\n`
  }

  const postal_code = variables["zip/postal code"] || variables["postal_code"]
  const region = variables["state/region"] || variables["region"]

  if (
    variables["country"] &&
    (["US"].indexOf(variables["country"]) > -1 ||
      ["CA"].indexOf(variables["country"]) > -1)
  ) {
    if (variables["city"] && region) {
      address += `${to_title_case(variables["city"]).trim()} ${region}`
      if (postal_code) address += ` ${postal_code}\n`
    } else if (variables["city"]) {
      address += `${to_title_case(variables["city"]).trim()}`
      if (postal_code) address += ` ${postal_code}\n`
    } else if (region) {
      address += `${region}`
      if (postal_code) address += ` ${postal_code}\n`
    } else {
      if (postal_code) address += `${postal_code}\n`
    }
  } else if (
    variables["country"] &&
    ["DE"].indexOf(variables["country"]) > -1
  ) {
    if (variables["city"] && postal_code) {
      address += `${postal_code} ${to_title_case(variables["city"]).trim()}\n`
    }
  } else {
    if (variables["city"] && region) {
      address += `${to_title_case(variables["city"]).trim()}, ${region}\n`
    } else if (variables["city"]) {
      address += `${to_title_case(variables["city"]).trim()}\n`
    } else if (region) {
      address += `${region}\n`
    }

    if (postal_code) address += `${postal_code}\n`
  }

  // Only include country if not GB or US
  if (
    variables["country"] &&
    (include_country_name || ["GB"].indexOf(variables["country"]) === -1) &&
    ["US"].indexOf(variables["country"]) === -1
  ) {
    if (
      variables["country"] &&
      countriesList[variables["country"].toUpperCase()]
    ) {
      address += `${countriesList[variables["country"]]}\n`
    } else if (variables["country"]) {
      address += `${variables["country"]}\n`
    }
  }

  variables["address"] = address.trim()

  for (var key in variables) {
    if (variables[key]) {
      template = template.replace(
        new RegExp(`{{{${key}}}}`, "g"),
        variables[key]
      )
    }
  }

  template = template.replace(/{{{.+}}}/g, "") // Clean any missing

  return template
}

function textarea_variable_highlight(text) {
  const txt = text
    .replace(/\n$/g, "\n\n")
    .replace(
      /{{{first name}}}|{{{last name}}}|{{{department}}}|{{{title}}}|{{{company}}}|{{{city}}}|{{{custom 1}}}|{{{custom 2}}}|{{{custom 3}}}|{{{custom 4}}}|{{{custom 5}}}|{{{custom 6}}}|{{{custom 7}}}|{{{custom 8}}}|{{{custom 9}}}|{{{custom 10}}}/gm,
      (x) => `<mark class="highlight">${x}</mark>`
    )
    .replace(/{{{/g, '<span style="opacity: 0;">{{{</span>')
    .replace(/\}\}\}/g, '<span style="opacity: 0;">}}}</span>')

  return txt
}

function is_tag(section) {
  var result = section.match(/<\/?(.*)>/)
  if (!result) {
    return false
  }

  return [result[1], result[0].match(/\//) ? -1 : 1]
}

function sub_chars(text) {
  let clean_text = text

  clean_text = clean_text.replace(/&nbsp;/g, " ")
  clean_text = clean_text.replace(/&amp;/g, "&")
  clean_text = clean_text.replace(/<br>/g, "\n")

  clean_text = clean_text.replace(/ ,/g, ",")
  clean_text = clean_text.replace(/ \./g, ".")
  clean_text = clean_text.replace(/,+/g, ",")
  clean_text = clean_text.replace(/\r\n|\r|\n/g, "\n")

  for (const char in ILLEGAL_CHARACTERS) {
    clean_text = clean_text.replace(
      new RegExp(char, "g"),
      ILLEGAL_CHARACTERS[char]
    )
  }

  return clean_text
}

function random() {
  seed = seed + 1
  var x = Math.sin(seed) * 10000
  return x - Math.floor(x)
}

function getRandomInt(seed, max) {
  return Math.floor(random() * Math.floor(max))
}

/*eslint-disable */
async function resize_text(
  user_font_size_mm,
  container_selector,
  text_selector
) {
  let delta = 0.2
  let time = 50
  let buffer = 50

  let font_size_mm = user_font_size_mm

  if (font_size_mm <= 1 && font_size_mm >= user_font_size_mm - delta) {
    return
  }

  let change = false
  while ($(container_selector).height() >= $(text_selector).height + buffer) {
    // No overflow
    change = true

    if (font_size_mm >= user_font_size_mm) {
      break
    }

    font_size_mm += delta
    $(text_selector).css({ "font-size": `${font_size_mm}mm` })
    await new Promise((resolve) => setTimeout(r, time))
  }

  if (change) {
    return
  }

  while ($(container_selector).height() < $(text_selector).height() + +buffer) {
    // Overflow
    font_size_mm -= delta
    $(text_selector).css({ "font-size": `${font_size_mm}mm` })
    await new Promise((resolve) => setTimeout(r, time))

    if (font_size_mm <= 1) {
      break
    }
  }
}
/* eslint-enable */

/**
 * Shuffles array in place. ES6 version
 * @param {Array} a items An array containing the items.
 */
function shuffle(seed, a) {
  for (let i = a.length - 1; i > 0; i--) {
    const j = Math.floor(random() * (i + 1))
    ;[a[i], a[j]] = [a[j], a[i]]
  }
  return a
}

function wrap_characters(_seed, text, letter_variants, debug, config) {
  if (!config) {
    config = {}
  }

  const keys = [
    { tag: "title", handlebars: "title" },
    { tag: "first_name", handlebars: "first name" },
    { tag: "last_name", handlebars: "last name" },
    { tag: "department", handlebars: "department" },
    { tag: "company", handlebars: "company" },
    { tag: "city", handlebars: "city" },
    { tag: "custom_1", handlebars: "custom 1" },
    { tag: "custom_2", handlebars: "custom 2" },
    { tag: "custom_3", handlebars: "custom 3" },
    { tag: "custom_4", handlebars: "custom 4" },
    { tag: "custom_5", handlebars: "custom 5" },
    { tag: "custom_6", handlebars: "custom 6" },
    { tag: "custom_7", handlebars: "custom 7" },
    { tag: "custom_8", handlebars: "custom 8" },
    { tag: "custom_9", handlebars: "custom 9" },
    { tag: "custom_10", handlebars: "custom 10" },
  ]
  for (const item of keys) {
    text = text.replace(
      new RegExp(`{{{${item.handlebars}}}}`, "g"),
      `<${item.tag}></${item.tag}>`
    )
  }

  seed = 0 + _seed

  var s = sub_chars(text).split(/(<.*?>)/)

  const colours = ["red", "blue", "green", "yellow", "purple", "orange", "pink"]

  var html = ""
  var count = 0
  for (var section of s) {
    var result = is_tag(section)
    if (result) {
      if (result[1] === 1) {
        let added = false
        for (const item of keys) {
          if (result[0] === item.tag) {
            html += `<span style='position: relative; top: -4px;'></span><span contenteditable="false" style="font-family: sans-serif; border: 1px solid black; border-radius: 8px; padding-left: 0.5em; padding-right: 0.5em; position: relative; top: -4px; font-size: small;">${item.handlebars.toUpperCase()}`
            added = true
          }
        }

        if (!added) {
          html += section
        }
        //  else {
        //   html += `<span class="${result[0]}">`
        // }
      } else {
        let added = false
        for (const item of keys) {
          if (result[0] === item.tag) {
            html += `</span>`
            added = true
          }
        }

        if (!added) {
          html += section
        }
      }
      continue
    }

    const top_max = parseFloat(config.top_max) || 2.5
    const sleep_max = parseFloat(config.sleep_max) || 15
    let top = -top_max
    let direction = parseFloat(config.direction) || 0.4
    let sleep = getRandomInt(seed, sleep_max)
    const do_sleep = parseFloat(config.do_sleep) || 0.05

    const character_variants = {}
    for (const character of section) {
      count++

      if (
        !character_variants[character] ||
        character_variants[character].length === 0
      ) {
        const vs = []
        for (let i = 0; i < letter_variants; i++) {
          vs[i] = i + 1
        }
        character_variants[character] = shuffle(seed, vs)
      }

      const variant = character_variants[character].pop()

      let colour = "none"
      if (debug) {
        colour = colours[variant - 1]
      }

      if (sleep) {
        sleep--
      } else {
        if (random(seed) <= do_sleep) {
          sleep = getRandomInt(seed, sleep_max)
          if (random(seed) < 0.5) {
            direction = -1 * direction
          }
        }

        top += direction
        if (top >= top_max || top <= -top_max) {
          direction = -1 * direction
        }
      }

      html += `<span style='font-feature-settings: "ss0${variant}"; background-color: ${colour}; position: relative; top: ${top}px;'>${character}</span>`
    }
  }

  return [html, count]
}

export default {
  TEXT_TEMPLATES: TEXT_TEMPLATES,
  get_variables(text) {
    const groups = text.match(/{{{(.+?)}}}/g)
    const variables = []
    if (groups) {
      for (const variable of groups) {
        variables.push(variable.replace(/{{{/g, "").replace(/}}}/g, ""))
      }
    }
    return variables
  },
  replace_unsupported_chars(text) {
    let new_text = text

    new_text = new_text.replace(/[ăāª]/g, "a")
    new_text = new_text.replace(/[сćč]/g, "c")
    new_text = new_text.replace(/[еēėę]/g, "e")
    new_text = new_text.replace(/[¡ıīį]/g, "i")
    new_text = new_text.replace(/[ł]/g, "l")
    new_text = new_text.replace(/[ń]/g, "n")
    new_text = new_text.replace(/[оðōº]/g, "o")
    new_text = new_text.replace(/[р]/g, "p")
    new_text = new_text.replace(/[şś]/g, "s")
    new_text = new_text.replace(/[ū]/g, "u")
    new_text = new_text.replace(/[źż]/g, "z")

    new_text = new_text.replace(/[АĀ]/g, "A")
    new_text = new_text.replace(/[ĆČ]/g, "C")
    new_text = new_text.replace(/[ĒĖĘ]/g, "E")
    new_text = new_text.replace(/[н]/g, "H")
    new_text = new_text.replace(/[İĪĮ]/g, "I")
    new_text = new_text.replace(/[Ł]/g, "L")
    new_text = new_text.replace(/[иŃ]/g, "N")
    new_text = new_text.replace(/[Ō]/g, "O")
    new_text = new_text.replace(/[ŞŚ]/g, "S")
    new_text = new_text.replace(/[т]/g, "T")
    new_text = new_text.replace(/[Ū]/g, "U")
    new_text = new_text.replace(/[ŹŻ]/g, "Z")

    new_text = new_text.replace(/\[/g, "(")
    new_text = new_text.replace(/\]/g, ")")

    return new_text
  },
  check_bad_text(text, that, prepend, show_toast, remove_unsupported = false) {
    if (!prepend) {
      prepend = ""
    }

    var new_text = remove_illigal_characters(text)

    if (remove_unsupported)
      new_text = new_text.replace(
        /[^\{\}a-zA-Z0-9!\?<>#_@$£€¢§¶%&*()\-+=.,\,;:'"\ \/ \n\r\t\\n\\r\\t|`ßÂâÃãÁáÀàÅåÄäÆæÇçÉéÈèËëÊêÍíÌìÎîÏïÑñÔôÖöÒòÓóŒœØøÕõŠšÚúÙùÜüÛûŸÿŽž]/gm,
        ""
      )

    var search_text = new_text.replace(/{{{.+}}}/g, "")
    let bad_chars = []
    /* eslint-disable-next-line */
    if (
      !/^[\x00-\x7F]*$/.test(search_text) ||
      /[\{\}\[\]\^\\_<>\`]/.test(search_text)
    ) {
      // eslint-disable-next-line
      bad_chars = _.uniq(
        search_text
          .replace(
            /[\{\}a-zA-Z0-9!\?<>#_@$£€¢§¶%&*()\-+=.,\,;:'"\ \/ \n\r\t\\n\\r\\t|`ßÂâÃãÁáÀàÅåÄäÆæÇçÉéÈèËëÊêÍíÌìÎîÏïÑñÔôÖöÒòÓóŒœØøÕõŠšÚúÙùÜüÛûŸÿŽž]/gm,
            ""
          )
          .trim()
          .split("")
      )

      if (bad_chars && bad_chars.length) {
        if (show_toast) {
          that.$bvToast.toast(
            `${prepend}Character currently not supported. Please remove characters: "${bad_chars.join(
              '", "'
            )}".`,
            {
              title: `Unsupported character`,
              autoHideDelay: 5000,
              variant: "danger",
              appendToast: true,
              // static: true
            }
          )
        }

        // that.$swal('Unsupported character', `${prepend}Character currently not supported. Please remove characters: ${bad_chars.join(', ')}.`, 'warning')
        text = new_text
        return [false, new_text, bad_chars]
      }
    }

    text = new_text
    return [true, new_text, bad_chars]
  },
  to_title_case,
  template_text,
  textarea_variable_highlight,
  wrap_characters,
  is_tag,
}
