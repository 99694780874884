// State
/**
 * Module state
 * @typedef {Object} State
 * @property {Plan | null} plan - Plan data
 * @property {boolean} loading - Whether the plan data is being loaded
 * @property {String} [error] - Error message
 */
export default {
  loading: false,
  plan: null,
  planIndex: null,
  changePlanResponse: {},
  changePlanError: "",
  changePlanLoader: false,
  cancelPlanLoader: false,
  invoicesLoader: false,
  invoices: [],
  cancelPlanResponse: {},
}
