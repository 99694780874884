import Vue from "vue"
import Router from "vue-router"
import { isMobile } from "mobile-device-detect"

// Dashboard
// const Dashboard = () => import(/* webpackChunkName: "dashboard" */ "@/pages/Dashboard.vue")

// Campaigns
const CampaignList = () =>
  import(/* webpackChunkName: "campaign_list" */ "@/pages/CampaignList.vue")
const NewCampaign = () => import(/* webpackChunkName: "new_campaign" */ "@/pages/NewCampaign.vue")
const Campaign = () => import(/* webpackChunkName: "campaign" */ "@/pages/Campaign.vue")

// Stationery
const StationaryList = () =>
  import(/* webpackChunkName: "stationary_list" */ "@/pages/StationaryList.vue")
const StationeryEdit = () =>
  import(/* webpackChunkName: "stationary_edit" */ "@/pages/StationeryEdit.vue")

// Batches
const BatchList = () => import(/* webpackChunkName: "batch_list" */ "@/pages/admin/BatchList.vue")
const Batch = () => import(/* webpackChunkName: "batch" */ "@/pages/admin/Batch.vue")

// Users
const UserList = () => import(/* webpackChunkName: "user_list" */ "@/pages/admin/UserList.vue")
const User = () => import(/* webpackChunkName: "user" */ "@/pages/admin/User.vue")
const UserManagement = () =>
  import(/* webpackChunkName: "user_management" */ "@/pages/UserManagement.vue")
const Team = () => import(/* webpackChunkName: "team" */ "@/pages/Team.vue")

// Audience
const Audience = () => import(/* webpackChunkName: "audience" */ "@/pages/Audience.vue")
const AudienceDetail = () =>
  import(/* webpackChunkName: "audience_detail" */ "@/pages/AudienceDetail.vue")

// Settings
const Settings = () => import(/* webpackChunkName: "settings" */ "@/pages/Settings.vue")
const General = () =>
  import(/* webpackChunkName: "settings_general" */ "@/components/settings/General.vue")
const BillingHome = () =>
  import(/* webpackChunkName: "billing_home" */ "@/components/billing/BillingHome.vue")
const BillingDashboard = () =>
  import(/* webpackChunkName: "billing_dashboard" */ "@/components/billing/BillingDashboard.vue")
const BillingPlans = () =>
  import(/* webpackChunkName: "billing_plans" */ "@/components/billing/BillingPlans.vue")
const Payments = () =>
  import(/* webpackChunkName: "payments" */ "@/components/billing/Payments.vue")
const Printers = () =>
  import(/* webpackChunkName: "settings_printers" */ "@/components/settings/Printers.vue")
const Notifications = () =>
  import(/* webpackChunkName: "settings_notifications" */ "@/components/settings/Notifications.vue")

const Admin = () => import(/* webpackChunkName: "user_admin" */ "@/pages/admin/Admin.vue")

// Admin
const PrinterList = () =>
  import(/* webpackChunkName: "printer_list" */ "@/pages/admin/PrinterList.vue")
const Printer = () => import(/* webpackChunkName: "printer" */ "@/pages/admin/Printer.vue")
const Billing = () => import(/* webpackChunkName: "billing" */ "@/pages/Billing.vue")
const RecipientList = () =>
  import(/* webpackChunkName: "recipient_list" */ "@/pages/admin/RecipientList.vue")
const Analytics = () => import(/* webpackChunkName: "analytics" */ "@/pages/admin/Analytics.vue")

// Misc
const Integrations = () => import(/* webpackChunkName: "integrations" */ "@/pages/Integrations.vue")
const Components = () => import(/* webpackChunkName: "components" */ "@/pages/Components.vue")
const Referral = () => import(/* webpackChunkName: "referral" */ "@/pages/Referral.vue")

Vue.use(Router)

const router = new Router({
  mode: "history",
  routes: [
    {
      path: "/",
      name: "Dashboard",
      component: CampaignList,
      props: true,
      meta: { title: `Dashboard | Scribeless`, mobile_friendly: true },
    },
    {
      path: "/stationery",
      name: "Stationery",
      component: StationaryList,
      props: true,
      meta: { title: `Stationery | Scribeless` },
    },
    {
      path: "/edit/stationery/:campaign_id",
      name: "EditStationery",
      component: StationeryEdit,
      props: true,
      meta: { title: `Edit Stationery | Scribeless` },
    },
    {
      path: "/audience/:id",
      alias: "/audience/:id/*",
      name: "AudienceDetail",
      component: AudienceDetail,
      props: true,
      meta: { title: `Audience | Scribeless` },
    },
    {
      path: "/team",
      name: "Team",
      component: Team,
      meta: { title: `Team | Scribeless` },
    },
    {
      path: "/team/:id",
      name: "Team",
      component: Team,
      meta: { title: `Team | Scribeless` },
    },
    {
      path: "/settings",
      name: "Settings",
      component: Settings,
      meta: { title: `Settings | Scribeless`, mobile_friendly: true },

      children: [
        {
          path: "general",
          name: "General",
          component: General,
          meta: { title: `Settings | Scribeless` },
        },
        {
          path: "printers",
          name: "Printers",
          component: Printers,
          meta: { title: `Settings | Scribeless` },
        },
        {
          path: "notifications",
          name: "Notifications",
          component: Notifications,
          meta: { title: `Settings | Scribeless` },
        },

        {
          path: "billing",
          name: "BillingHome",
          component: BillingHome,
          meta: { title: `Settings | Scribeless` },
          children: [
            {
              path: "",
              name: "BillingDashboard",
              component: BillingDashboard,
              meta: { title: `Settings | Scribeless` },
            },

            {
              path: "plans",
              name: "BillingPlans",
              component: BillingPlans,
              meta: { title: `Settings | Scribeless` },
            },
            {
              path: "payments",
              name: "Payments",
              component: Payments,
              meta: { title: `Settings | Scribeless` },
            },
          ],
        },
      ],
    },

    {
      path: "/integration",
      name: "Integrations",
      component: Integrations,
      meta: { title: `Integrations | Scribeless` },
    },
    // ? Deprecated
    {
      path: "/referral",
      name: "Referral",
      component: Referral,
      meta: { title: `Referral` },
    },
    // ? Deprecated
    {
      path: "/audience",
      name: "Audience",
      component: Audience,
      meta: { title: `Audience | Scribeless` },
    },
    {
      path: "/billing",
      name: "Billing",
      component: Billing,
      meta: { title: `Billing | Scribeless` },
    },

    {
      path: "/create/:id",
      alias: "/create/:id/*",
      name: "CreateNewCampaign",
      component: NewCampaign,
      props: true,
      meta: { title: "New Campaign | Scribeless" },
    },
    {
      path: "/campaigns",
      name: "CampaignList",
      component: CampaignList,
      meta: { title: "Campaigns | Scribeless" },
    },
    {
      path: "/campaign/:id",
      name: "Campaign",
      component: Campaign,
      meta: { title: "Campaign | Scribeless" },
    },
    // ? Deprecated
    // {
    //   path: "/campaignPreview/:id",
    //   name: "CampaignPreview",
    //   component: CampaignPreview,
    //   meta: { title: "CampaignPreview | Scribeless" },
    // },
    // {
    //   path: "/campaign/:id/billing",
    //   component: CampaignBilling,
    //   name: "CampaignBilling",
    //   meta: { title: "Campaign Billing | Scribeless" },
    //   props: true,
    // },
    // {
    //   path: "/campaign/:id/billing/savedPaymentMethods",
    //   component: CampaignSavedPaymentMethods,
    //   name: "CampaignSavedPaymentMethods",
    //   meta: { title: "Campaign Billing | Scribeless" },
    //   props: true,
    // },
    // {
    //   path: "/campaign/:id/billing/payByCard",
    //   component: PayByCard,
    //   name: "PayByCard",
    //   meta: { title: "Campaign Billing | Scribeless" },
    //   props: true,
    // },
    // {
    //   path: "/campaign/:id/billing/payByInvoice",
    //   component: PayByInvoice,
    //   name: "PayByInvoice",
    //   meta: { title: "Campaign Billing | Scribeless" },
    //   props: true,
    // },
    {
      path: "/batches",
      name: "BatchList",
      component: BatchList,
      meta: { title: "Batches | Scribeless" },
    },
    {
      path: "/batch/:id",
      name: "Batch",
      component: Batch,
      meta: { title: "Batch | Scribeless" },
    },
    {
      path: "/recipients",
      name: "RecipientList",
      component: RecipientList,
      meta: { title: "Recipients | Scribeless" },
    },
    {
      path: "/users",
      name: "UserList",
      component: UserList,
      meta: { title: "Users | Scribeless" },
    },
    // ? Deprecated
    {
      path: "/analytics",
      name: "Analytics",
      component: Analytics,
      meta: { title: "Analytics | Scribeless" },
    },
    {
      path: "/printers",
      name: "PrinterList",
      component: PrinterList,
      meta: { title: "Printers | Scribeless" },
    },
    {
      path: "/printer/:id",
      name: "Printer",
      component: Printer,
      meta: { title: "Printer | Scribeless" },
    },
    {
      path: "/user/:id",
      name: "User",
      component: User,
      meta: { title: "User | Scribeless" },

      children: [
        {
          path: "general",
          name: "General",
          component: General,
          meta: { title: `Admin | Scribeless` },
        },
        {
          path: "printers",
          name: "Printers",
          component: Printers,
          meta: { title: `Admin | Scribeless` },
        },
        {
          path: "notifications",
          name: "Notifications",
          component: Notifications,
          meta: { title: `Admin | Scribeless` },
        },
        {
          path: "admin",
          name: "Admin",
          component: Admin,
          meta: { title: `Admin | Scribeless` },
        },

        {
          path: "billing",
          name: "BillingHome",
          component: BillingHome,
          meta: { title: `Admin | Scribeless` },
          children: [
            {
              path: "",
              name: "BillingDashboard",
              component: BillingDashboard,
              meta: { title: `Admin | Scribeless` },
            },

            {
              path: "plans",
              name: "BillingPlans",
              component: BillingPlans,
              meta: { title: `Admin | Scribeless` },
            },
            {
              path: "payments",
              name: "Payments",
              component: Payments,
              meta: { title: `Admin | Scribeless` },
            },
          ],
        },
      ],
    },
    {
      path: "/logout",
      name: "Logout",
      component: Settings,
      meta: { title: "Logout | Scribeless", mobile_friendly: true },
    },
    // ? Design Library - Deprecated
    {
      path: "/components",
      name: "Components",
      component: Components,
      meta: { title: "Components | Scribeless" },
    },
    {
      path: "/user-management",
      name: "UserManagement",
      component: UserManagement,
      meta: {
        title: (route) => {
          return `${get_title_from_mode(route.query.mode)} | Scribeless`
        },
      },
    },
    { path: "*", redirect: { name: "Dashboard" } },
  ],
})

router.beforeEach((to, from, next) => {
  /*Reroute all non mobile firendly routes to dashboard for mobile/tablet viewports */
  if (isMobile) {
    if (!to.meta.mobile_friendly) {
      next({
        path: "/",
      })
    }
  }
  // if (from.path.indexOf('edit') > -1 && from.path.indexOf('stationery') > -1) {
  //   // Pass
  // } else if (from.path.indexOf('stationery') > -1 && to.path.indexOf('stationery') === -1 && to.path.match(/campaign\/.+/) == null) {
  //   var result = confirm('You may lose unsaved work if you go back (to go back a stage click on the stage number). Are you sure you want to go back?')
  //   window.popStateDetected = false
  //   if (!result) {
  //     next(false)
  //     return ''
  //   }
  // }
  window.popStateDetected = false
  if (typeof to.meta.title == "function") {
    document.title = to.meta.title(to)
  } else {
    document.title = to.meta.title
  }
  next()
})

router.afterEach(() => window.Intercom("update"))

function get_title_from_mode(mode) {
  switch (mode) {
    case "resetPassword":
      return "Forgot Password"
    case "recoverEmail":
      return "Forgot Email"
    case "signIn":
      return "Sign In"
    default:
      return "Error"
  }
}

export default router
