import api from "../../../services/api"

export default {
  async getPlan({ commit }, user_id) {
    try {
      commit("startLoading")
      const result = await api.get_plan(user_id)
      commit("updatePlan", result.data)
      commit("calculatePlanIndex", result.data)
    } catch (error) {
      console.error(error)
      commit("hasError", "Something went wrong...")
    } finally {
      commit("stopLoading")
    }
  },
  async changePlan(context, data) {
    try {
      context.commit("setChangePlanLoader", true)

      const response = await api.change_plan(
        data.user_id,
        data.plan,
        data.base,
        data.price_overrides,
      )

      context.commit("changeUserPlan", response)
      context.commit("setChangePlanLoader", false)
    } catch (error) {
      console.error(error)
      context.commit("hasError", "Something went wrong...")
    } finally {
      context.commit("setChangePlanLoader", false)
    }
  },
  async cancelPlan(context, data) {
    try {
      context.commit("setCancelPlanLoader", true)

      const response = await api.cancel_plan(data.user_id)

      context.commit("cancelUserPlan", response)
      context.commit("setCancelPlanLoader", false)
    } catch (error) {
      console.error(error)
      context.commit("hasError", "Something went wrong...")
    } finally {
      context.commit("setCancelPlanLoader", false)
    }
  },
  async getUserInvoices(context, data) {
    try {
      context.commit("setInvoicesLoader", true)

      const response = await api.get_customer_invoices(
        data.uid,
        data.limit,
        data.starting_after,
        data.end_before,
      )

      context.commit("getUserInvoices", response.data.data.data)
      context.commit("setInvoicesLoader", false)
    } catch (error) {
      console.error(error)
      context.commit("hasError", "Something went wrong...")
    } finally {
      context.commit("setInvoicesLoader", false)
    }
  },
}
