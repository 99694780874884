import campaign_billing from "./campaign_billing"

const getters = campaign_billing.getters
const actions = campaign_billing.actions
const mutations = campaign_billing.mutations
const state = campaign_billing.state

export default {
  getters,
  actions,
  mutations,
  state,
}
