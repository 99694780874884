import user from "./user"

const getters = user.getters
const actions = user.actions
const mutations = user.mutations
const state = user.state

export default {
  getters,
  actions,
  mutations,
  state,
}
