export default {
  plan: (state) => state.plan,
  planIndex: (state) => state.planIndex,
  getLoadingState: (state) => {
    return state.loading
  },
  changePlanResponse: (state) => {
    return state.changePlanResponse
  },
  cancelPlanResponse: (state) => {
    return state.cancelPlanResponse
  },
  invoices: (state) => {
    return state.invoices
  },
  invoicesLoader: (state) => {
    return state.invoicesLoader
  },
}
