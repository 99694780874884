import { functions } from "@/lib/firebase"
import config from "@/lib/config"
import constants from "@shared/constants"

function handle_error_response(swal, response) {
  if (!response.success) {
    console.warn(response)
    swal("Error", response.error_message, "error")
    return true
  }

  return false
}

export default {
  handle_error_response,
  recalculate_recipients(campaign_ids) {
    return functions.httpsCallable("recalculate_recipients")({
      campaigns: campaign_ids,
    })
  },
  run_status_check(campaign_id) {
    return functions.httpsCallable("run_status_check")({
      campaign_id: campaign_id,
    })
  },
  generate_login_link(email) {
    return functions.httpsCallable("generate_login_link")({ email })
  },
  delete_user() {
    return functions.httpsCallable("delete_user")({})
  },
  update_all_items(obj) {
    return functions.httpsCallable("update_all_items")(obj)
  },
  stripe_request_setupintent() {
    return functions.httpsCallable("stripe_request_setupintent")({})
  },
  stripe_add_card(payment_method_id, tax_id, tax_type) {
    return functions.httpsCallable("stripe_add_card")({
      payment_method_id,
      tax_id,
      tax_type,
    })
  },
  stripe_add_coupon(coupon) {
    return functions.httpsCallable("stripe_add_coupon")({ coupon })
  },
  rerender(recipient_id, campaign_id) {
    return functions.httpsCallable("rerender_recipient")({
      recipient_id,
      campaign_id,
    })
  },
  add_recipients(recipients, campaign_id) {
    return functions.httpsCallable("add_recipients")({
      recipients,
      campaign_id,
      source: constants.RECIPIENT_SOURCE.interface,
    })
  },
  set_hubspot_connection_session(uid) {
    const options = {
      method: "POST",
      body: JSON.stringify({ uid: uid }),
      headers: { "Content-Type": "application/json" },
    }

    return fetch(`${config.firebase_config.functions}/hubspot/auth`, options).then((response) => {
      return response.json()
    })
  },
  bill_user(uid) {
    return functions.httpsCallable("bill_user")({ uid })
  },
  bill_users() {
    return functions.httpsCallable("bill_users")()
  },
  sync_hubspot_invoices() {
    return functions.httpsCallable("sync_hubspot_invoices")()
  },
  bill_user_for_campaign_recipients(campaign_id) {
    return functions.httpsCallable("bill_user_for_campaign_recipients")({
      campaign_id,
    })
  },
  stationery_needs_bleeds(stationery) {
    return functions.httpsCallable("stationery_needs_bleeds")({ stationery })
  },
  zip_batch(batch_id) {
    return functions.httpsCallable("zip_batch")({ batch_id })
  },
  finalize_batches() {
    return functions.httpsCallable("finalize_batches")()
  },
  stripe_get_payment_methods(paymentMethodType, userId) {
    return functions.httpsCallable("stripe_get_payment_methods")({
      paymentMethodType,
      userId,
    })
  },
  stripe_get_customer(uid) {
    return functions.httpsCallable("stripe_get_customer")({ uid })
  },
  async stripe_remove_card(payment_method_id) {
    return functions.httpsCallable("stripe_remove_card")({ payment_method_id })
  },
  async stripe_payment_make_default(payment_method_id) {
    return functions.httpsCallable("stripe_payment_make_default")({
      payment_method_id,
    })
  },
  stripe_create_pricing(price, currency, product_id) {
    return functions.httpsCallable("stripe_create_pricing")({
      price,
      currency,
      product_id,
    })
  },
  stripe_create_checkout(payment_method_type, line_items, mode, success_url, cancel_url) {
    return functions.httpsCallable("stripe_create_checkout")({
      payment_method_type,
      line_items,
      mode,
      success_url,
      cancel_url,
    })
  },
  stripe_create_invoice(collection_method, days_until_due, price) {
    return functions.httpsCallable("stripe_create_invoice")({
      collection_method,
      days_until_due,
      price,
    })
  },
  create_subscription_record(
    uid,
    credit_quantity,
    subscription_good,
    source,
    payment_method,
    start_date,
    initial_start_date,
    credit_rollover_percentage,
    campaign_id,
  ) {
    return functions.httpsCallable("create_subscription_record")({
      uid,
      credit_quantity,
      subscription_good,
      source,
      payment_method,
      start_date,
      initial_start_date,
      credit_rollover_percentage,
      campaign_id,
    })
  },
  charge_for_one_off_campaign(campaign_id, payment_method) {
    return functions.httpsCallable("charge_for_one_off_campaign")({
      campaign_id,
      payment_method,
    })
  },
  get_users_invoices(uid, limit) {
    return functions.httpsCallable("get_users_invoices")({ uid, limit })
  },
  get_invoice_by_id(id) {
    return functions.httpsCallable("get_invoice_by_id")({ id })
  },
  get_users_subscriptions(uid) {
    return functions.httpsCallable("get_users_subscriptions")({ uid })
  },
  get_subscription_from_campaign(campaign) {
    return functions.httpsCallable("get_subscription_from_campaign")({
      campaign,
    })
  },
  get_users_credits(uid) {
    return functions.httpsCallable("get_users_credits")({ uid })
  },
  calculate_remaining_credits(uid, campaign = null) {
    return functions.httpsCallable("calculate_remaining_credits")({
      uid,
      campaign,
    })
  },
  get_unpaid_credits_for_campaign(campaign_id) {
    return functions.httpsCallable("get_unpaid_credits_for_campaign")({
      campaign_id,
    })
  },
  calculate_one_off_campaign_charge_details(campaign_id) {
    return functions.httpsCallable("calculate_one_off_campaign_charge_details")({ campaign_id })
  },
  calculate_subscription_charge_details(uid, credit_quantity, subscription_good) {
    return functions.httpsCallable("calculate_subscription_charge_details")({
      uid,
      credit_quantity,
      subscription_good,
    })
  },
  create_credits(user_id, source, expiry_date, quantity, credit_good_name, status) {
    return functions.httpsCallable("create_credits")({
      user_id,
      source,
      expiry_date,
      quantity,
      credit_good_name,
      status,
    })
  },
  get_usage(user_id) {
    return functions.httpsCallable("stripe_get_usage")({ user_id })
  },
  get_plan(user_id) {
    return functions.httpsCallable("stripe_get_plan")({ user_id })
  },
  change_plan(uid, plan, base, price_overrides) {
    return functions.httpsCallable("change_user_subscription")({
      uid,
      plan,
      base,
      price_overrides,
    })
  },
  get_customer_invoices(uid, limit, starting_after, ending_before) {
    return functions.httpsCallable("get_customer_invoices")({
      uid,
      limit,
      starting_after,
      ending_before,
    })
  },
  cancel_plan(uid) {
    return functions.httpsCallable("cancel_user_subscription")({ uid })
  },
  create_plan(uid, plan, base) {
    return functions.httpsCallable("create_user_subscription")({
      uid,
      plan,
      base,
    })
  },
  get_user_monthly_aggregated_usage(user_id) {
    return functions.httpsCallable("get_user_monthly_aggregated_usage")({
      user_id,
    })
  },
  invoice_has_been_paid_by_id(invoice_id) {
    return functions.httpsCallable("invoice_has_been_paid_by_id")({
      invoice_id,
    })
  },
  create_printnode_account() {
    return functions.httpsCallable("create_printnode_account")({})
  },
  get_printnode_printers(user_id) {
    return functions.httpsCallable("get_printnode_printers")({ user_id })
  },
  add_printer(printer_data) {
    return functions.httpsCallable("add_printer")({ printer_data })
  },
  cancel_subscription(uid, subscription_id) {
    return functions.httpsCallable("cancel_subscription")({
      uid,
      subscription_id,
    })
  },
  sync_subscription(uid, subscription_id) {
    return functions.httpsCallable("sync_subscription")({
      uid,
      subscription_id,
    })
  },
  reset_billing_cycle(uid, subscription_id, campaign = null) {
    return functions.httpsCallable("reset_billing_cycle")({
      uid,
      subscription_id,
      campaign,
    })
  },
  ignore_held_recipients(campaign_id) {
    return functions.httpsCallable("ignore_held_recipients")({
      campaign_id,
    })
  },
  pay_for_held_recipients(campaign_id) {
    return functions.httpsCallable("pay_for_held_recipients")({
      campaign_id,
    })
  },
}
