import dayjs from "dayjs"
import localizedFormat from "dayjs/plugin/localizedFormat"
import isSameOrBefore from "dayjs/plugin/isSameOrBefore"
import isBetween from "dayjs/plugin/isBetween"
import { TEAM_ROLES } from "@shared/constants"
import { db } from "./firebase"

// Support for advanced formating options
dayjs.extend(localizedFormat)
dayjs.extend(isSameOrBefore)
dayjs.extend(isBetween)

export const DATETIME_OPTIONS = {
  date: "L",
  date_time: "L HH:mm",
  date_time_seconds: "L HH:mm:ss",
}

/**
 * Convert a date string, unix timestamp or Firestore Timestamp to a locale formatted date string
 * @param {string | number | import('firebase').firestore.Timestamp} timestamp Timestamp to convert
 * @param {string} [format=DATETIME_OPTIONS.date_time] Format to convert to
 * @returns {string}
 */
export const displayTimestamp = (timestamp, format = DATETIME_OPTIONS.date_time) => {
  let date

  if (!timestamp) return

  try {
    // Date 
    if (timestamp instanceof Date) date = dayjs(timestamp)
    // String
    else if (typeof timestamp === "string") date = dayjs(timestamp)
    // Unix timestamp
    else if (typeof timestamp === "number") date = dayjs.unix(timestamp)
    // Firestore Timestamp
    else date = dayjs(timestamp.toDate())

    if (!date.isValid()) return `${timestamp}`
  } catch (e) {
    console.warn(`Couldn't parse timestamp: ${timestamp} - type: ${typeof timestamp}`, timestamp)
    console.error(e)
    return `${timestamp}`
  }

  return date.format(format)
}

export const copyToClipboard = (textToCopy) => {
  let status = false

  const clipBoardElem = document.createElement("input")
  document.body.appendChild(clipBoardElem)
  clipBoardElem.value = textToCopy
  clipBoardElem.select()
  status = document.execCommand("copy")
  document.body.removeChild(clipBoardElem)

  return status
}

export const hasBillingAccess = (user_profile) => {
  let hasAccess = true

  if (user_profile && user_profile.hasOwnProperty("team_data")) {
    if (
      user_profile.team_data["role"] != TEAM_ROLES.admin &&
      user_profile.team_data["role"] != TEAM_ROLES.creator
    ) {
      hasAccess = false
    }
  }

  return hasAccess
}

export const isUsingBetaPricing = async (user_profile) => {
  if (!user_profile.hasOwnProperty("team_data") && user_profile.hasOwnProperty("pricing")) {
    return true
  }

  if (user_profile.hasOwnProperty("team_data")) {
    const team_doc = await db.collection("teams").doc(user_profile.team_data.team_id).get()

    const team_data = team_doc.data()
    const user_doc = await db.collection("users").doc(team_data.creator_uid).get()
    const user_data = user_doc.data()

    if (user_data.hasOwnProperty("pricing")) {
      return true
    }
  }

  return false
}
