<template>
  <div class="left-side-menu bg-primary">
    <perfect-scrollbar style="position: fixed">
      <div
        class="slimScrollDiv"
        style="
          position: relative;
          overflow: hidden;
          width: 220px;
          height: 100%;
          z-index: 4;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
        "
      >
        <div
          class="slimscroll-menu text-white d-flex align-items-center flex-column"
          style="overflow: hidden; width: auto; height: auto"
        >
          <img
            src="../../static/images/logo_white.svg"
            width="70%"
            style="height: 50px"
            class="center mt-4 mb-2"
            alt="Scribeless Logo"
          />

          <span
            v-if="CONFIG.environment != 'production'"
            class="badge badge-pill badge-warning"
          >
            {{ CONFIG.environment | uppercase }}
          </span>

          <!--- Sidebar Menu Begins -->
          <div id="sidebar-menu" class="active mt-2 w-100">
            <ul class="metismenu in" id="side-menu">
              <li v-for="item in menuItems" v-bind:key="item.route">
                <template v-if="'header' in item">
                  <span class="text-white menu-title">
                    {{ item.header | uppercase }}
                  </span>
                </template>

                <template v-else-if="'href' in item">
                  <a
                    :href="item.href"
                    target="_blank"
                    class="d-flex align-items-start"
                  >
                    <div style="width: 18%" class="pl-2">
                      <font-awesome-icon
                        :icon="['fas', item.icon]"
                        class="text-white"
                      />
                    </div>
                    <span
                      class="pl-2 text-white menu-label"
                      >{{ item.label }}</span
                    >
                  </a>
                </template>

                <template v-else>
                  <router-link
                    :to="item.route"
                    class="d-flex align-items-start"
                  >
                    <div style="width: 18%" class="pl-2">
                      <font-awesome-icon
                        :icon="item.icon"
                        class="text-white"
                      />
                    </div>
                    <span
                      class="pl-2 text-white menu-label"
                      >{{ item.label }}</span
                    >
                  </router-link>
                </template>
              </li>
            </ul>
          </div>

          <!-- Sidebar Menu Ends -->

          <div class="clearfix"></div>
        </div>

        <!-- Bottom Menu -->
        <div class="align-bottom">
          <ul class="p-0 m-0">
            <!-- <li>
              <div class="launcher-circle bg-primary" />
              <span class="pl-3 text-white">Livechat</span>
            </li> -->
            <li @click="logout">
              <div class="launcher-circle bg-primary">
                <img
                  v-if="user_profile && user_profile.avatar"
                  :src="user_profile.avatar"
                  alt="user-image"
                  class="rounded-circle bg-white"
                />
                <div v-else class="initials">
                  <avatar
                    :username="`${user_profile ? user_profile.first_name : ''} ${
                      user_profile ? user_profile.last_name : ''
                    }`"
                    :size="48"
                    color="#ffffff"
                    background-color="#344697"
                  >
                  </avatar>
                </div>
              </div>
              <span class="pl-3 text-white">Sign out</span>
            </li>
          </ul>
        </div>

        <div
          class="slimScrollBar"
          style="
            background: rgb(158, 165, 171);
            width: 8px;
            position: absolute;
            top: 0px;
            opacity: 0.4;
            display: none;
            border-radius: 7px;
            z-index: 99;
            right: 1px;
            height: 848.291px;
          "
        ></div>
        <div
          class="slimScrollRail"
          style="
            width: 8px;
            height: 100%;
            position: absolute;
            top: 0px;
            display: none;
            border-radius: 7px;
            background: rgb(51, 51, 51);
            opacity: 0.2;
            z-index: 90;
            right: 1px;
          "
        ></div>
      </div>
      <!-- Sidebar -left -->
    </perfect-scrollbar>
  </div>
</template>

<script>
import { auth } from "@/lib/firebase.js"
import Vue from "vue"
import PerfectScrollbar from "vue2-perfect-scrollbar"
import "vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css"
import Avatar from "vue-avatar"
import { hasBillingAccess, isUsingBetaPricing } from "@/lib/generics"

Vue.use(PerfectScrollbar)

export default {
  name: "TheMenu",
  props: {
    user_profile: {
      required: true,
    },
  },
  components: {
    Avatar,
  },
  data() {
    return {
      showDropdown: false,
      isBetaPricing: false,
      hasBillingAccess: false,
    }
  },
  methods: {
    logout: async function () {
      await auth.signOut()
      this.$router.push("/")
    },
    async checkBetaPricing() {
      this.isBetaPricing = await isUsingBetaPricing(this.user_profile)
    },
    async checkBillingAccess() {
      this.hasBillingAccess = await hasBillingAccess(this.user_profile)
    },
  },
  computed: {
    menuItems() {
      let menuItems = []

      // Home
      menuItems.push({
        label: "Home",
        icon: "fa-solid fa-chart-bar",
        route: "/",
      })

      // Campaigns
      menuItems.push({
        label: "Campaigns",
        icon: "envelope-open-text",
        route: "/campaigns",
      })

      // Integrations
      if (this.user_profile && !("parent_id" in this.user_profile))
        menuItems.push({
          label: "Integrations",
          icon: "plug",
          route: "/integration",
        })

      // ? - Legacy Billing
      // if (!this.isBetaPricing && this.hasBillingAccess)
      //   menuItems.push({
      //     label: "Billing",
      //     icon: "file-invoice-dollar",
      //     route: "/billing",
      //   })

      // Team
      menuItems.push({
        label: "Team",
        icon: "user-plus",
        route: "/team",
      })

      // Settings
      menuItems.push({
        label: "Settings",
        icon: "gear",
        route: "/settings",
      })

      // Support
      // menuItems.push({
      //   label: "Support",
      //   icon: "question-circle",
      //   href: "https://scribeless.co/support-center",
      // })

      // Admin Routes
      if (this.user_profile && this.user_profile.admin) {
        menuItems = menuItems.concat([
          {
            header: "Admin",
          },
          {
            label: "Recipients",
            icon: "users",
            route: "/recipients",
          },
          {
            label: "Batches",
            icon: "folder",
            route: "/batches",
          },
          {
            label: "Users",
            icon: "users",
            route: "/users",
          },
          {
            label: "Printers",
            icon: "print",
            route: "/printers",
          },
        ])
      }

      return menuItems
    },
    arrowLeft() {
      const arrow = "turn-arrow"
      if (this.showDropdown === true) {
        return arrow
      }
      return true
    },
  },
  mounted() {
    this.checkBetaPricing()
    this.checkBillingAccess()
  },
}
</script>

<style>
.ps {
  height: 100%;
}

.align-bottom > ul > li {
  display: flex;
  align-items: center;
  margin: 20px 15px;
  cursor: pointer;
}

.launcher-circle {
  /* Fixed width and height to match Intercom launcher */
  height: 48px;
  width: 48px;
  border-radius: 50%;
  box-shadow: 0px 0 9px 0px #8b94b8 !important;
}

.launcher-circle > img {
  height: 48px;
  width: 48px;
}

.initials {
  display: inline-block;
}

.initials > div > span {
  font-size: 18px;
  font-family: "SF Pro Display", sans-serif;
}

.enlarged .ps {
  overflow: visible !important;
}

.enlarged .left-side-menu #sidebar-menu > ul > li > a {
  text-align: center;
}
</style>
