export default {
  savedPaymentMethodsList: [],
  customerData: {},
  loading: false,
  billingType: "",
  paymentType: "",
  noteCount: 0,
  selectedCard: "",
  removeCardResponse: "",
  makePaymentDefaultResponse: "",
  makePaymentDefaultLoading: false,
}
