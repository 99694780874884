import billing from "./billing"

const getters = billing.getters
const actions = billing.actions
const mutations = billing.mutations
const state = billing.state

export default {
  getters,
  actions,
  mutations,
  state,
}
