export default {
  getSavedPaymentMethods: (state, payload) => {
    state.savedPaymentMethodsList = payload
  },
  getStripeCustomer: (state, payload) => {
    state.customerData = payload
  },
  getLoadingState: (state, payload) => {
    state.loading = payload
  },
  selectBillingType: (state, payload) => {
    state.billingType = payload
  },
  selectPaymentType: (state, payload) => {
    state.paymentType = payload
  },
  selectNoteCount: (state, payload) => {
    state.noteCount = payload
  },
  selectCard: (state, payload) => {
    state.selectedCard = payload
  },
  stripeRemoveCard: (state, payload) => {
    state.removeCardResponse = payload
  },
  stripePaymentMakeDefault: (state, payload) => {
    state.makePaymentDefaultResponse = payload
  },
  stripePaymentMakeDefaultLoading: (state, payload) => {
    state.makePaymentDefaultLoading = payload
  },
}
