import api from "../../../services/api"

export default {
  async getSavedPaymentMethods(context, data) {
    try {
      context.commit("getLoadingState", true)
      const response = await api.stripe_get_payment_methods(data.paymentMethodType, data.userId)

      const _data = response.data

      if (_data.status == "success") {
        context.commit("getSavedPaymentMethods", _data.payment_methods_list.data)
      }

      context.commit("getLoadingState", false)
    } catch (error) {
      console.error(error)
      context.commit("hasError", "Something went wrong...")
    } finally {
      context.commit("getLoadingState", false)
    }
  },

  async getStripeCustomer(context, data) {
    try {
      const response = await api.stripe_get_customer(data.uid)
      const _data = response.data

      if (_data.status == "success") {
        context.commit("getStripeCustomer", _data.customer)
      }
    } catch (error) {
      console.error(error)
      context.commit("hasError", "Something went wrong...")
    }
  },

  selectBillingType: (context, value) => {
    context.commit("selectBillingType", value)
  },
  selectPaymentType: (context, value) => {
    context.commit("selectPaymentType", value)
  },
  selectNoteCount: (context, value) => {
    context.commit("selectNoteCount", value)
  },
  selectCard: (context, value) => {
    context.commit("selectCard", value)
  },
  async stripeRemoveCard(context, data) {
    try {
      const response = await api.stripe_remove_card(data.payment_method_id)
      context.commit("stripeRemoveCard", response)
    } catch (error) {
      console.error(error)
      context.commit("hasError", "Something went wrong...")
    }
  },
  async stripePaymentMakeDefault(context, data) {
    try {
      context.commit("stripePaymentMakeDefaultLoading", true)
      const response = await api.stripe_payment_make_default(data.payment_method_id)
      context.commit("stripePaymentMakeDefault", response)

      context.commit("stripePaymentMakeDefaultLoading", false)
    } catch (error) {
      console.error(error)
      context.commit("hasError", "Something went wrong...")
    } finally {
      context.commit("stripePaymentMakeDefaultLoading", false)
    }
  },
}
