import Vue from "vue"

import {
  LayoutPlugin,
  ModalPlugin,
  CardPlugin,
  DropdownPlugin,
  TablePlugin,
  TableSimplePlugin,
  FormGroupPlugin,
  FormPlugin,
  FormInputPlugin,
  ButtonPlugin,
  SpinnerPlugin,
  FormSelectPlugin,
  ButtonGroupPlugin,
  ButtonToolbarPlugin,
  LinkPlugin,
  FormTextareaPlugin,
  FormCheckboxPlugin,
  BadgePlugin,
  ListGroupPlugin,
  TabsPlugin,
  FormFilePlugin,
  InputGroupPlugin,
  AlertPlugin,
  TooltipPlugin,
  PopoverPlugin,
  CollapsePlugin,
  FormRadioPlugin,
  ToastPlugin,
  ImagePlugin
} from "bootstrap-vue"
import VueFormWizard from "vue-form-wizard"
import Vue2Filters from "vue2-filters"
import VueLodash from "vue-lodash"
import { KinesisContainer, KinesisElement } from "vue-kinesis"
import AsyncComputed from "vue-async-computed"
import VCalendar from "v-calendar"

import "vue-form-wizard/dist/vue-form-wizard.min.css"
import "@/assets/custom.scss"

import * as constants from "@shared/constants.js"
import config from "./config.js"
import text_utils from "./text_utils.js"
import { displayTimestamp, DATETIME_OPTIONS } from "./generics"

import { library } from '@fortawesome/fontawesome-svg-core'

import {
  faPlug,
  faPause,
  faPlay,
  faFileInvoiceDollar,
  faUserPlus,
  faCog,
  faChartBar,
  faCopy,
  faQuestionCircle,
  faFolder,
  faPrint,
  faExternalLinkAlt,
  faLink,
  faPlusCircle,
  faFileArchive,
  faCheck,
  faCheckCircle,
  faMinusCircle,
  faChevronDown,
  faSignOut,
  faEdit,
  faFile,
  faEnvelope,
  faCaretDown,
  faTimesCircle,
  faAlignRight,
  faAlignCenter,
  faAlignLeft,
  faExclamationTriangle,
  faEquals,
  faSignature,
  faUndo,
  faPaintBrush,
  faClone,
  faCalendar,
  faCalendarAlt,
  faFileAlt,
  faMoneyBillAlt,
  faCalendarCheck,
  faClock,
  faUser,
  faEye,
  faArrowRight,
  faArrowLeft,
  faBars,
  faCloudDownloadAlt,
  faDownload,
  faEnvelopeOpenText,
  faFileCsv,
  faFire,
  faGift,
  faInfoCircle,
  faMousePointer,
  faPencil,
  faSpinner,
  faTrash,
  faTree,
  faTruck,
  faUsers,
  faUpload,
  faQuestion,
} from "@fortawesome/free-solid-svg-icons"
import { faImage } from "@fortawesome/free-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome"

library.add(
  faImage,
  faEnvelopeOpenText,
  faUsers,
  faPlug,
  faCopy,
  faPause,
  faPlay,
  faFileInvoiceDollar,
  faUserPlus,
  faCog,
  faQuestionCircle,
  faFolder,
  faPrint,
  faChartBar,
  faExternalLinkAlt,
  faLink,
  faPlusCircle,
  faFileArchive,
  faCheck,
  faCheckCircle,
  faMinusCircle,
  faChevronDown,
  faSignOut,
  faEdit,
  faFile,
  faEnvelope,
  faCaretDown,
  faTimesCircle,
  faAlignRight,
  faAlignCenter,
  faAlignLeft,
  faExclamationTriangle,
  faEquals,
  faSignature,
  faUndo,
  faPaintBrush,
  faClone,
  faArrowRight,
  faArrowLeft,
  faBars,
  faCalendar,
  faCalendarAlt,
  faFileAlt,
  faTruck,
  faMoneyBillAlt,
  faMousePointer,
  faFileCsv,
  faCloudDownloadAlt,
  faInfoCircle,
  faCalendarCheck,
  faDownload,
  faClock,
  faUpload,
  faGift,
  faQuestion,
  faTrash,
  faUser,
  faSpinner,
  faPencil,
  faUser,
  faTree,
  faEye,
  faFire,
)

Vue.component("font-awesome-icon", FontAwesomeIcon)
Vue.component("kinesis-container", KinesisContainer)
Vue.component("kinesis-element", KinesisElement)

const {
  DELAY_DELIVERY_DAYS,
  BATCH_STATUS,
  MIN_DELIVERY_DATE,
  PRODUCT,
  MIN_UNITS,
  MAX_UNITS,
  CAMPAIGN_STATUS,
  CAMPAIGN_STATUS_ALL,
  RECIPIENT_STATUS,
  PAPER_SIZES,
  ENVELOPE_SIZES,
  HANDWRITING_SIZE,
  HANDWRITING_STYLE,
  HEADER_TYPE,
  STAMP_TYPES,
  DATA_SOURCE,
  SENDER,
  ENVELOPE_REQUIRED,
  FONTS,
  GOOD,
  CAMPAIGN_PAYMENT_TYPE,
  PAYMENT_METHOD,
  SUBSCRIPTION_GOOD,
  SUBSCRIPTION_SOURCE,
  CAMPAIGN_STATUS_DETAILS,
  INTEGRATION_SOURCE,
  USER_GOOD_MAPPING,
  TEAM_ROLES,
  CREDIT_STATUS,
} = constants

Vue.mixin({
  data: function () {
    return {
      CAMPAIGN_STATUS: CAMPAIGN_STATUS,
      CAMPAIGN_STATUS_ALL,
      RECIPIENT_STATUS: RECIPIENT_STATUS,
      PAPER_SIZES: PAPER_SIZES,
      ENVELOPE_SIZES: ENVELOPE_SIZES,
      HANDWRITING_SIZE: HANDWRITING_SIZE,
      HANDWRITING_STYLE: HANDWRITING_STYLE,
      HEADER_TYPE: HEADER_TYPE,
      STAMP_TYPES: STAMP_TYPES,
      DATA_SOURCE: DATA_SOURCE,
      SENDER: SENDER,
      DELAY_DELIVERY_DAYS: DELAY_DELIVERY_DAYS,
      ENVELOPE_REQUIRED: ENVELOPE_REQUIRED,
      FONTS: FONTS,
      MIN_UNITS: MIN_UNITS,
      MAX_UNITS: MAX_UNITS,
      PRODUCT: PRODUCT,
      MIN_DELIVERY_DATE: MIN_DELIVERY_DATE,
      CONFIG: config,
      BATCH_STATUS: BATCH_STATUS,
      GOOD: GOOD,
      CONSTANTS: constants,
      CAMPAIGN_PAYMENT_TYPE: CAMPAIGN_PAYMENT_TYPE,
      PAYMENT_METHOD: PAYMENT_METHOD,
      SUBSCRIPTION_GOOD: SUBSCRIPTION_GOOD,
      SUBSCRIPTION_SOURCE: SUBSCRIPTION_SOURCE,
      CAMPAIGN_STATUS_DETAILS: CAMPAIGN_STATUS_DETAILS,
      INTEGRATION_SOURCE: INTEGRATION_SOURCE,
      USER_GOOD_MAPPING: USER_GOOD_MAPPING,
      TEAM_ROLES: TEAM_ROLES,
      CREDIT_STATUS: CREDIT_STATUS,
      DATETIME_OPTIONS,
    }
  },
  filters: {
    to_title_case: text_utils.to_title_case,
  },
  methods: {
    display_timestamp: displayTimestamp,
  },
})

Vue.config.productionTip = false

// Bootstrap Plugins
Vue.use(LayoutPlugin)
Vue.use(ModalPlugin)
Vue.use(CardPlugin)
Vue.use(DropdownPlugin)
Vue.use(TablePlugin)
Vue.use(TableSimplePlugin)
Vue.use(FormGroupPlugin)
Vue.use(FormPlugin)
Vue.use(FormInputPlugin)
Vue.use(FormSelectPlugin)
Vue.use(FormCheckboxPlugin)
Vue.use(FormTextareaPlugin)
Vue.use(FormFilePlugin)
Vue.use(FormRadioPlugin)
Vue.use(SpinnerPlugin)
Vue.use(ButtonPlugin)
Vue.use(ButtonGroupPlugin)
Vue.use(ButtonToolbarPlugin)
Vue.use(LinkPlugin)
Vue.use(BadgePlugin)
Vue.use(ListGroupPlugin)
Vue.use(TabsPlugin)
Vue.use(InputGroupPlugin)
Vue.use(AlertPlugin)
Vue.use(TooltipPlugin)
Vue.use(PopoverPlugin)
Vue.use(CollapsePlugin)
Vue.use(ToastPlugin)
Vue.use(ImagePlugin)

Vue.use(Vue2Filters)
Vue.use(VueFormWizard)
Vue.use(VueLodash)
Vue.use(AsyncComputed)

Vue.use(VCalendar, {
  componentPrefix: "vc",
})
