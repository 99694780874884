import * as constants from "@shared/constants"
// Mutations
/**
 * Set the plan data
 * @param {State} state Current state
 * @param {Plan} plan Plan data to update state with
 */

export default {
  updatePlan: (state, plan) => {
    state.plan = { ...state.plan, ...plan }
  },
  calculatePlanIndex: (state, plan) => {
    for (const [index, item] of Object.entries(constants["PRICING_MODEL"]).entries()) {
      if (item[0] == plan.name) {
        state.planIndex = index
      }
    }
  },
  startLoading: (state) => {
    state.loading = true
  },
  stopLoading: (state) => {
    state.loading = false
  },
  hasError: (state, error) => {
    state.error = error
  },
  cancelUserPlan: (state, payload) => {
    state.cancelPlanResponse = payload
  },
  changeUserPlan: (state, payload) => {
    state.changePlanResponse = payload
  },
  changePlanError: (state, payload) => {
    state.changePlanError = payload
  },
  setChangePlanLoader: (state, payload) => {
    state.changePlanLoader = payload
  },
  setCancelPlanLoader: (state, payload) => {
    state.changePlanLoader = payload
  },
  setInvoicesLoader: (state, payload) => {
    state.invoicesLoader = payload
  },
  getUserInvoices: (state, payload) => {
    state.invoices = payload
  },
}
