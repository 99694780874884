<template>
  <div data-gr-c-s-loaded="true" :class="{ enlarged: isActive }">
    <div v-if="!loading" id="wrapper">
      <div style="display: flex; min-height: 100vh">
        <TheMenu
          v-if="$route.path.indexOf('onboarding') === -1 && !isMobile && logged_in"
          :user_profile="user_profile"
        />
        <div
          :class="
            $route.path.indexOf('onboarding') === -1 && !isMobile && logged_in
              ? `content-container menu-adjustment`
              : 'content-container'
          "
        >
          <Login
            v-if="$route.path != '/user-management' && $route.path.indexOf('onboarding') === -1"
            :logged_in="logged_in"
          />
          <div v-if="$route.path.indexOf('onboarding') === -1 && logged_in" class="content-page">
            <div class="content">
              <router-view
                v-if="
                  (logged_in && Object.keys(user_profile || {}).length) ||
                  $route.path == '/user-management'
                "
                :sidebar="sidebar"
                :logged_in="logged_in"
                :user_profile="user_profile"
                :team_data="team_data"
                :price_table="price_table"
              />
            </div>
          </div>
          <div v-else>
            <router-view
              v-if="Object.keys(user_profile || {}).length || $route.path == '/user-management'"
              :sidebar="sidebar"
              :logged_in="logged_in"
              :user_profile="user_profile"
              :team_data="team_data"
              :price_table="price_table"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { isMobile } from "mobile-device-detect"
import * as Sentry from "@sentry/vue"

import { db, auth } from "@/lib/firebase.js"
import config from "@/lib/config.js"
import api from "@/services/api"

import Login from "@/components/Login.vue"
import TheMenu from "@/containers/TheMenu.vue"

export default {
  name: "App",
  components: {
    Login,
    TheMenu,
  },
  data() {
    return {
      isMobile: isMobile,
      isActive: false,
      isMenuCondensed: false,
      logged_in: undefined,
      user_profile: {},
      team_data: {},
      sub_profile: {},
      loading: true,
      role: undefined,
      parent_id: undefined,
      goods: [],
      user_goods: [],
      sidebar: {
        open: true,
      },
    }
  },
  computed: {
    team_id() {
      let team_id = null
      if (this.user_profile && this.user_profile.team_data && this.user_profile.team_data.team_id) {
        team_id = this.user_profile.team_data.team_id
      }
      return team_id
    },
    price_table: function () {
      const price_table = {}
      for (const product of this.goods || []) {
        let added = false
        for (const user_good of this.user_goods || []) {
          if (user_good.id === product.id) {
            added = true
            price_table[product.id] = user_good
            price_table[product.id].override = true
          }
        }
        if (!added) {
          price_table[product.id] = product
        }
      }
      return price_table
    },
  },
  watch: {
    user_profile: {
      deep: true,
      handler: async function (val) {
        if (this.logged_in) {
          if (this.user_profile && !this.user_profile.uid) {
            this.user_profile.uid = this.user_profile.id
          }

          const currentDateTime = new Date()
          const currentDate = `${currentDateTime.getFullYear()}-${
            currentDateTime.getMonth() + 1
          }-${currentDateTime.getDate()}`
          const lastLoginDate = this.user_profile.last_updated?.toDate() ?? new Date()
          const lastLogin = `${lastLoginDate.getFullYear()}-${
            lastLoginDate.getMonth() + 1
          }-${lastLoginDate.getDate()}`

          if (currentDate !== lastLogin) {
            await db
              .collection("users")
              .doc(this.user_profile.uid)
              .update({
                last_updated: new Date(),
              })
          }

          // ! TODO: Check why this isn't being set correctly on signup
          if (!this.user_profile.address) {
            await db
              .collection("users")
              .doc(this.user_profile.uid)
              .update({
                address: {
                  country: this.user_profile.country || "GB",
                  last_name: this.user_profile.last_name || "",
                  first_name: this.user_profile.first_name || "",
                },
              })
          }

          if (val && val.email) {
            Sentry.setUser({
              email: val.email,
              id: val.id,
              username: val.email,
            })

            window.Intercom("boot", {
              api_base: "https://api-iam.intercom.io",
              app_id: "swx18lqz",
              user_id: val.id, // User id
              name: val.full_name, // Full name
              email: val.email, // the email for your user
              created_at: parseInt((val.created.toDate().getTime() / 1000).toFixed(0)), // Signup date as a Unix timestamp
            })

            this.$gtm.trackEvent({
              event: "identify_user",
              user_id: val.id,
              email: val.email,
              display_name: val.full_name,
            })
          }
          this.loading = false
          if (val) {
            this.$bind("goods", db.collection("goods"))
            this.$bind("user_goods", db.collection("users").doc(val.id).collection("goods"))
          }
        } else {
          Sentry.setUser(null)
          window.Intercom('shutdown')
        }
      },
    },
    team_id: function (val) {
      if (val) {
        this.bind_team_data(val)
      }
    },
  },
  mounted() {
    // if (isMobile) {
    //   this.$swal(
    //     "Info",
    //     "Please use a desktop version for the best user experience.",
    //     "info"
    //   )
    // }
    this.$parent.$on("toggleNavNew", () => {
      this.isActive = !this.isActive
    })
  },
  created() {
    window.pagesense = window.pagesense || []
    auth.onAuthStateChanged(async (user) => {
      if (user) {
        this.logged_in = true
        await this.fetch_profile()
      } else {
        this.logged_in = false
        this.loading = false
        this.user_profile = {}
        
        if (window.gtag) {
          window.gtag("gtm1.set", {
            email: undefined,
            user_id: undefined,
          })
        }
        if (window.ga) {
          window.ga("gtm1.set", "email", undefined)
          window.ga("gtm1.set", "userId", undefined)
        }
      }
      if (this.$route.path.indexOf("logout") > -1) {
        auth.signOut()
      }
    })
  },
  methods: {
    toggle_menu() {
      this.isMenuCondensed = !this.isMenuCondensed
      document.body.classList.toggle("sidebar-enable")
      document.body.classList.toggle("enlarged")
    },
    collapse_menu() {
      // disabled menu close
      // document.body.classList.add('sidebar-enable')
      // document.body.classList.add('enlarged')
    },
    show_menu() {
      document.body.classList.remove("sidebar-enable")
      document.body.classList.remove("enlarged")
    },
    toggleRightSidebar() {
      document.body.classList.toggle("right-bar-enabled")
    },
    async fetch_profile() {
      const that = this
      that.$bind("user_profile", db.collection("users").doc(auth.currentUser.uid))

      // const loggedinUser = await db
      //   .collection("users")
      //   .doc(auth.currentUser.uid)
      //   .get()
      //   .then(async function (doc) {
      //     if (doc.exists) {
      //     }
      //   })
    },
    bind_team_data(team_id) {
      const team_ref = db.collection("teams").doc(team_id)
      this.$bind("team_data", team_ref)
    },
  },
}
</script>

<style>
.nav-location {
  margin-left: 220px;
}
.nav-mobile {
  background: transparent;
  margin-left: 0 !important;
}
</style>
