import { firestoreAction } from "vuexfire"
import "firebase/firestore"
import { db } from "../../lib/firebase"

// State object
const state = {
  sample_count: 0,
  campaigns: [],
}

// Actions
const actions = {
  increment({ commit, dispatch, getters }) {
    commit("INCREMENT")
  },
  bindCampaigns: firestoreAction(({ bindFirestoreRef }) => {
    // return the promise returned by `bindFirestoreRef`
    return bindFirestoreRef("campaigns", db.collection("campaigns").limit(10))
  }),
}

// Mutations
const mutations = {
  INCREMENT(state) {
    state.sample_count++
  },
}

// Getter functions
const getters = {
  counter(state) {
    return state.sample_count
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
