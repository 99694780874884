import { ENVELOPE_REQUIRED, PRODUCT } from '@shared/constants'
import 'firebase/firestore'
import { firestoreAction, vuexfireMutations } from 'vuexfire'

import { db } from '../../lib/firebase'

const default_state_object = {
  auto_discard_held: false,
  auto_pay_held: false,
  created: null,
  creator_uid: '',
  currency: '',
  data_source: '',
  delay_delivery_days: 0,
  delivery: {
    due_date: null,
    include_country_name: false,
    include_return_address: false,
    sender: '',
    user_address: {
      address_line_1: '',
      address_line_2: '',
      city: '',
      company: '',
      country: '',
      first_name: '',
      job_title: '',
      last_name: '',
      postcode: '',
      region: '',
    },
  },
  envelope: {
    paper: {
      height: '-1',
      iso_standard: '',
      key: '',
      name: '',
      paper: '',
      us_standard: '',
      width: '',
    },
    required: '',
    sealed: true,
    stamp: '',
    inserts: null,
    include_return_address: false,
    return_address: {
      address_line_1: '',
      address_line_2: '',
      city: '',
      company: '',
      country: '',
      first_name: '',
      job_title: '',
      last_name: '',
      postcode: '',
      region: '',
    },
    envelope_stationery: {
      handwriting_obj: null,
    },
  },
  first_recipient_preview: null,
  integration_source: null,
  invoice_id: null,
  is_greetings_card_flow: false,
  is_paid_initially: false,
  is_sample: false,
  last_updated: null,
  notes: '',
  payment_type: null,
  payment_method: null,
  product: '',
  recipients_count: 0,
  recipients_data: null,
  source: 'interface',
  stationary: '',
  stationary_required: true,
  status: '',
  status_check: false,
  team_id: '',
  team_id_or_uid: '',
  testing: false,
  text: '',
  title: '',
  uid: '',
  updated: null,
  is_ready: false,
}
// State object
const state = {
  campaign: default_state_object,
}

// Actions
const actions = {
  update_delivery_information({ commit }, delivery_information) {
    commit('update_delivery_information', delivery_information)
  },

  update_envelope_information({ commit }, envelope_information) {
    commit('update_envelope_information', envelope_information)
  },

  update_envelope_handwriting_colour({ commit }, envelope_handwriting_colour) {
    commit('update_envelope_handwriting_colour', envelope_handwriting_colour)
  },
  update_envelope_handwriting_style({ commit }, envelope_handwriting_style) {
    commit('update_envelope_handwriting_style', envelope_handwriting_style)
  },
  set_title({ commit }, title) {
    commit('set_title', title)
  },

  set_text({ commit }, text) {
    commit('set_text', text)
  },

  set_data_source({ commit }, data_source) {
    commit('set_data_source', data_source)
  },

  set_api_crm_delay({ commit }, api_crm_delay) {
    commit('set_api_crm_delay', api_crm_delay)
  },

  set_integration_source({ commit }, integration_source) {
    commit('set_integration_source', integration_source)
  },

  set_recipients_data({ commit }, data) {
    commit('set_recipients_data', data)
  },

  set_stationery_required({ commit }, is_stationery_required) {
    commit('set_stationery_required', is_stationery_required)
  },

  set_stationery_uid({ commit }, uid) {
    commit('set_stationery_uid', uid)
  },

  set_due_date({ commit }, due_date) {
    commit('set_due_date', due_date)
  },

  set_sample({ commit }, sample) {
    commit('set_sample', sample)
  },

  set_campaign_ready({ commit }, is_ready) {
    commit('set_campaign_ready', is_ready)
  },

  duplicate_campaign({ commit }, campaign_object) {
    commit('duplicate_campaign', campaign_object)
  },

  update_user_address({ commit }, address) {
    commit('update_user_address', address)
  },

  set_include_return_address({ commit }, to_include) {
    commit('set_include_return_address', to_include)
  },

  set_payment_type({ commit }, payment_type) {
    commit('set_payment_type', payment_type)
  },

  set_updated_metadata({ commit }, metadata) {
    commit('set_updated_metadata', metadata)
  },

  set_greetings_card_flow({ commit }, is_greetings_card_flow) {
    commit('set_greetings_card_flow', is_greetings_card_flow)
  },

  update_bulk({ commit }, update_obj) {
    commit('update_bulk', update_obj)
  },

  clear_campaign({ commit }) {
    commit('clear_campaign')
  },

  bind_campaign: firestoreAction(({ bindFirestoreRef }, campaign_id) => {
    // return the promise returned by `bindFirestoreRef`
    if (!campaign_id) {
      return
    }
    return bindFirestoreRef(
      'campaign',
      db.collection('campaigns').doc(campaign_id),
    )
  }),

  unbind_campaign: firestoreAction(({ unbindFirestoreRef }) => {
    unbindFirestoreRef('campaign', () => default_state_object)
  }),
}

// Mutations
const mutations = {
  update_delivery_information(state, delivery_information) {
    if (delivery_information.product) {
      state.campaign.product = delivery_information.product
    }
    if (delivery_information.delivery) {
      state.campaign.delivery = {
        ...state.campaign.delivery,
        ...delivery_information.delivery,
      }
    }
  },

  update_envelope_information(state, envelope_information) {
    state.campaign.envelope = {
      ...state.campaign.envelope,
      ...envelope_information.envelope,
    }
  },

  update_envelope_handwriting_colour(state, envelope_handwriting_colour) {
    state.campaign.envelope.envelope_stationery.handwriting_obj = {
      ...state.campaign.envelope.envelope_stationery.handwriting_obj,
      handwriting_colour: envelope_handwriting_colour,
    }
  },
  update_envelope_handwriting_style(state, envelope_handwriting_style) {
    state.campaign.envelope.envelope_stationery.handwriting_obj = {
      ...state.campaign.envelope.envelope_stationery.handwriting_obj,
      handwriting_style: envelope_handwriting_style,
    }
  },

  set_title(state, title) {
    state.campaign.title = title
  },

  set_text(state, text) {
    state.campaign.text = text
  },

  set_data_source(state, data_source) {
    state.campaign.data_source = data_source
  },

  set_api_crm_delay(state, api_crm_delay) {
    state.campaign.delay_delivery_days = api_crm_delay
  },

  set_integration_source(state, integration_source) {
    state.campaign.integration_source = integration_source
  },

  set_recipients_data(state, data) {
    state.campaign.recipients_data = data
  },

  set_stationery_required(state, is_stationery_required) {
    state.campaign.stationary_required = is_stationery_required
  },

  set_stationery_uid(state, uid) {
    state.campaign.stationary = uid
  },

  set_due_date(state, due_date) {
    state.campaign.delivery.due_date = due_date
  },

  set_sample(state, sample) {
    state.campaign.is_sample = sample
    state.campaign.is_paid_initially = sample
  },

  set_campaign_ready(state, is_ready) {
    state.campaign.is_ready = is_ready
  },

  duplicate_campaign(state, campaign_object) {
    const {
      first_recipient_preview,
      invoice_id,
      recipients_count,
      last_updated,
      updated,
      is_ready,
      created,
      ...properties_to_be_copied
    } = campaign_object
    state.campaign = {
      ...state.campaign,
      ...properties_to_be_copied,
      first_recipient_preview: null,
    }
  },

  update_user_address(state, address) {
    state.campaign.delivery.user_address = address
  },

  set_include_return_address(state, to_include) {
    state.campaign.delivery.include_return_address = to_include
  },

  set_payment_type(state, payment_type) {
    state.campaign.payment_type = payment_type
  },

  set_updated_metadata(state, metadata) {
    state.campaign.updated = metadata.updated
    state.campaign.updated_by = metadata.updated_by
  },

  set_greetings_card_flow(state, is_greetings_card_flow) {
    state.campaign.is_greetings_card_flow = is_greetings_card_flow
  },

  update_bulk(state, update_obj) {
    state.campaign = {
      ...state.campaign,
      ...update_obj,
    }
  },

  clear_campaign() {
    state.campaign.recipients_data = null
  },

  ...vuexfireMutations,
}

// Getter functions
const getters = {
  campaign_object(state) {
    return state.campaign
  },
  delivery_information(state) {
    return {
      product: state.campaign.product,
      delivery: state.campaign.delivery,
    }
  },
  is_sample(state) {
    return state.campaign.is_sample
  },
  title(state) {
    return state.campaign.title
  },
  envelope_information(state) {
    return state.campaign.envelope
  },
  campaign_text(state) {
    return state.campaign.text
  },
  data_source(state) {
    return state.campaign.data_source
  },
  recipients_data(state) {
    return state.campaign.recipients_data
  },
  api_crm_delay(state) {
    return state.campaign.delay_delivery_days
  },
  is_stationery_required(state) {
    return state.campaign.stationary_required
  },
  is_envelope_required(state) {
    return state.campaign.envelope.required === ENVELOPE_REQUIRED.yes
  },
  is_first_name_required(state) {
    return (
      state.campaign.product !== PRODUCT.on_demand &&
      state.campaign.envelope.required === ENVELOPE_REQUIRED.yes
    )
  },
  is_campaign_ready(state) {
    return state.campaign.is_ready
  },
  is_greetings_card_flow(state) {
    return state.campaign.is_greetings_card_flow
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
