import Vue from "vue"

Vue.component("s-button", () => import("@/components/buttons/SButton"))
Vue.component("s-card", () => import("@/components/cards/SCard"))

Vue.component("s-range", () => import("@/components/inputs/SRange"))

Vue.component("s-icon", () => import("@/components/icons/SIcon"))

Vue.component("s-badge", () => import("@/components/badges/SBadge"))

Vue.component("s-container", () => import("@/components/containers/SContainer"))
