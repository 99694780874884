import Vue from "vue"
import "@/lib/vue_mixin"
import "@/sComponents"

import VueSweetalert2 from "vue-sweetalert2"
import VueGtm from "@gtm-support/vue2-gtm"
import VueSignaturePad from "vue-signature-pad"
import { firestorePlugin } from "vuefire"
import * as Sentry from "@sentry/vue"
import { Integrations } from "@sentry/tracing"
import { ExtraErrorData } from "@sentry/integrations"

import App from "@/App.vue"
import router from "@/router"
import config from "@/lib/config"
import store from "@/store"

const version = process.env.VERSION || "development"

// Mixin here as breaks tests
Vue.use(VueSweetalert2)
Vue.use(firestorePlugin)
Vue.use(VueSignaturePad)

Vue.use(VueGtm, {
  id: config.google_tag_manager.id,
  debug: process.env.NODE_ENV === "local",
  vueRouter: router,
  enabled: true,
  defer: true,
  compatibility: true,
})

if (process.env.NODE_ENV !== "local")
  Sentry.init({
    Vue,
    dsn: config.sentry.dsn,
    tracesSampleRate: 1.0,
    environment: process.env.NODE_ENV,
    attachStacktrace: true,
    autoSessionTracking: true,
    trackComponents: true,
    normalizeDepth: 3,
    hooks: ['activate', 'create', 'destroy', 'mount', 'update'],
    integrations: [
      new Sentry.Integrations.TryCatch({ eventTarget: false }),
      new Integrations.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracingOrigins: [
          'localhost',
          'hc-application-interface-dev.web.app',
          'app.scribeless.co',
          /^\//,
        ],
      }),
      new ExtraErrorData({ depth: 2 }),
    ],
  })

new Vue({
  el: "#app",
  router,
  components: { App },
  template: "<App />",
  store,
})
