import firebase from "firebase/app"
import "firebase/firestore"
import "firebase/storage"
import "firebase/functions"
import "firebase/auth"
import "firebase/performance"
import config from "./config"

firebase.initializeApp(config.firebase_config)

const fb = firebase
const db = firebase.firestore()
const storage = firebase.storage()
const functions = firebase.functions()
const auth = firebase.auth()
const perf = {} // firebase.performance()

const FieldValue = firebase.firestore.FieldValue
const Timestamp = firebase.firestore.Timestamp
const serverTimestamp = firebase.firestore.FieldValue.serverTimestamp
const firestoreDocumentID = firebase.firestore.FieldValue.documentId

async function upload(path, file) {
  const snapshot = await storage.ref().child(path).put(file)

  console.log(`uploading, ${path}`)
  console.log(`https://${config.firebase_config.storageBucket}/${path}?t=${Date.now()}`)

  return `https://${config.firebase_config.storageBucket}/${path}?t=${Date.now()}`
}

// https://firebase.google.com/docs/emulator-suite/connect_and_prototype?database=Firestore#web
if (
  process.env.NODE_ENV === "local" ||
  process.env.NODE_ENV === "local-firestore" ||
  process.env.NODE_ENV === "test"
) {
  functions.useFunctionsEmulator("http://0.0.0.0:5001")
  console.warn("Using firebase functions emulator.")
}

if (process.env.NODE_ENV === "local-firestore" || process.env.NODE_ENV === "test") {
  db.settings({ host: "0.0.0.0:8080", ssl: false })
  console.warn("Using firebase firestore emulator.")
}

export {
  db,
  storage,
  fb,
  functions,
  auth,
  perf,
  serverTimestamp,
  Timestamp,
  firestoreDocumentID,
  config,
  FieldValue,
  upload,
}
