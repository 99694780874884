const countriesTimezone = {
  GB: 0,
  US: -8,
  AF: 4,
  AX: 2,
  AL: 1,
  DZ: 1,
  AS: -11,
  AD: 1,
  AO: 1,
  AI: -4,
  AQ: 6,
  AG: -4,
  AR: -3,
  AM: 4,
  AW: 4,
  AU: 8,
  AT: 1,
  AZ: 4,
  BS: -5,
  BH: 3,
  BD: 6,
  BB: -4,
  BY: 3,
  BE: 1,
  BZ: -6,
  BJ: 1,
  BM: -4,
  BT: 6,
  BO: -4,
  BA: 1,
  BW: 2,
  BV: 2,
  BR: -4,
  IO: 6,
  BN: 8,
  BG: 8,
  BF: 0,
  BI: 2,
  KH: 7,
  CM: 1,
  CA: -6,
  CV: -1,
  KY: -5,
  CF: 1,
  TD: 1,
  CL: -4,
  CN: 8,
  CX: 7,
  CC: 6,
  CO: -5,
  KM: 3,
  CG: 1,
  CD: 1,
  CK: -10,
  CR: -6,
  CI: 0,
  HR: 1,
  CU: -5,
  CY: 2,
  CZ: 1,
  DK: -1,
  DJ: 3,
  DM: -4,
  DO: -4,
  EC: 5,
  EG: 2,
  SV: -6,
  GQ: 1,
  ER: 3,
  EE: 2,
  ET: 3,
  FK: -3,
  FO: 1,
  FJ: 12,
  FI: 2,
  FR: 1,
  GF: 3,
  PF: 10,
  TF: 5,
  GA: 1,
  GM: 0,
  GE: 4,
  DE: 1,
  GH: 0,
  GI: 1,
  GR: 2,
  GL: -3,
  GD: -4,
  GP: 4,
  GU: 10,
  GT: -6,
  GG: 0,
  GN: 1,
  GW: 0,
  GY: -4,
  HT: -5,
  HM: 5,
  VA: 1,
  HN: 6,
  HK: 8,
  HU: 1,
  IS: 0,
  IN: 5,
  ID: 8,
  IR: 3,
  IQ: 3,
  IE: 0,
  IM: 0,
  IL: 2,
  IT: 2,
  JM: -5,
  JP: 9,
  JE: 1,
  JO: 2,
  KZ: 5,
  KE: 3,
  KI: 13,
  KP: 9,
  KR: 9,
  XK: 1,
  KW: 3,
  KG: 6,
  LA: 7,
  LV: 2,
  LB: 2,
  LS: 2,
  LR: 0,
  LY: 2,
  LI: 1,
  LT: 2,
  LU: 1,
  MO: 8,
  MK: 1,
  MG: 3,
  MW: 2,
  MY: 8,
  MV: 5,
  ML: 0,
  MT: 1,
  MH: 12,
  MQ: -4,
  MR: 0,
  MU: 4,
  YT: 3,
  MX: -6,
  FM: 10,
  MD: 2,
  MC: 1,
  MN: 8,
  ME: 1,
  MS: -4,
  MA: 1,
  MZ: 2,
  MM: 6,
  NA: 1,
  NR: 12,
  NP: 5,
  NL: 1,
  AN: -4,
  NC: 11,
  NZ: 12,
  NI: -6,
  NE: 1,
  NG: 1,
  NU: -11,
  NF: 11,
  MP: 10,
  NO: 1,
  OM: 4,
  PK: 5,
  PW: 9,
  PS: 2,
  PA: -5,
  PG: 10,
  PY: -4,
  PE: -5,
  PH: 8,
  PN: -8,
  PL: 1,
  PT: 0,
  PR: -4,
  QA: 3,
  RE: 4,
  RO: 2,
  RU: 7,
  RW: 2,
  SH: 0,
  KN: -4,
  LC: -4,
  PM: -3,
  VC: -4,
  WS: -11,
  SM: -1,
  ST: 0,
  SA: 3,
  SN: 3,
  RS: 1,
  SC: 4,
  SL: 0,
  SG: 8,
  SK: 1,
  SI: 1,
  SB: 11,
  SO: 3,
  ZA: 2,
  GS: -2,
  ES: 1,
  LK: 5,
  SD: 2,
  SR: -3,
  SJ: 1,
  SZ: 2,
  SE: 1,
  CH: 1,
  SY: 2,
  TW: 8,
  TJ: 5,
  TZ: 3,
  TH: 7,
  TL: 9,
  TG: 0,
  TK: 13,
  TO: 13,
  TT: -4,
  TN: 1,
  TR: -3,
  TM: -5,
  TC: -5,
  TV: 12,
  UG: 3,
  UA: 2,
  AE: 4,
  UM: 12,
  UY: -3,
  UZ: 5,
  VU: 11,
  VE: -4,
  VN: 7,
  VG: 4,
  VI: -4,
  WF: 12,
  EH: 9,
  YE: 3,
  ZM: 2,
  ZW: 2,
}

export default countriesTimezone
