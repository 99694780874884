<template>
  <s-container class="bg-white" half fluid v-if="logged_in === false">
    <template #left>
      <div class="bg-primary" style="min-height: 100vh; height: 100%">
        <b-row class="logo-left-container">
          <img
            src="../../static/images/logo_white.svg"
            alt="Scribeless Logo"
            class="login-logo-left"
          />
        </b-row>
      </div>
    </template>
    <template #right>
      <b-row class="login-logo-right-container" align-v="center">
        <b-col class="text-center">
          <img
            class="mt-5"
            src="../../static/images/blue_logo.png"
            style="width: 40%"
            alt="Scribeless Logo"
          />
        </b-col>
      </b-row>
      <h2 class="mt-5 ml-5">{{ header_text }}</h2>
      <b-form class="mt-4 mr-5 ml-5" @submit="submit">
        <div v-if="signupForm">
          <b-form-group>
            <b-input
              @keyup.enter="signup"
              data-hj-whitelist
              v-model="full_name"
              placeholder="Enter Your Full Name"
              autocomplete="name"
            />
          </b-form-group>

          <b-form-group for="email">
            <b-input
              @keyup.enter="signup"
              data-hj-whitelist
              id="email"
              type="email"
              placeholder="Enter Your Email"
              v-model="email"
              :state="valid_email"
              autocomplete="email"
            />
            <b-form-invalid-feedback :state="valid_email">
              Enter a valid email
            </b-form-invalid-feedback>
          </b-form-group>

          <b-form-group
            v-if="!forgotPass"
            class="mb-1"
            description="At least 6 characters"
          >
            <b-input
              @keyup.enter="signup"
              data-hj-whitelist
              type="password"
              id="password"
              placeholder="Enter Your Password"
              v-model="password"
              :state="valid_password"
              autocomplete="current-password"
            />
            <b-form-invalid-feedback :state="valid_password">
              Enter a valid password
            </b-form-invalid-feedback>
          </b-form-group>

          <b-form-group :description="`Currency: ${currency.toUpperCase()}`">
            <CountrySelect v-model="country" />
          </b-form-group>
          <b-form-group>
            <b-form-checkbox v-model="tnc_accepted">
              I accept the
              <a
                href="https://scribeless.co/legal/terms-and-conditions"
                target="_blank"
                >terms and conditions</a
              >
            </b-form-checkbox>
          </b-form-group>

          <b-form-group class="mb-0 text-center">
            <b-button variant="btn btn-primary btn-block" block @click="signup"
              >Sign Up</b-button
            >
          </b-form-group>

          <b-row class="mt-3">
            <b-col class="text-center">
              <p v-if="signupForm">
                Already have an account?
                <a href="#" @click="toggleSignup" id="login_link">Sign In</a>
              </p>
            </b-col>
          </b-row>
        </div>

        <div v-else-if="forgotPass">
          <b-form-group for="email" label="Email">
            <b-input
              @keyup.enter="forgottonPassword"
              data-hj-whitelist
              id="email"
              type="email"
              v-model="email"
              :state="valid_email"
              autocomplete="username"
            />
            <b-form-invalid-feedback :state="valid_email">
              Enter a valid email
            </b-form-invalid-feedback>
          </b-form-group>

          <b-form-group class="text-center">
            <b-button
              variant="btn btn-primary btn-block"
              block
              @click="forgottonPassword"
              >Reset Password</b-button
            >
          </b-form-group>
          <p class="text-center">
            Back to<a href="#" @click="toggleForgotPass"> Login</a>
          </p>
        </div>

        <div v-else>
          <b-form-group for="email">
            <b-input
              @keyup.enter="login"
              data-hj-whitelist
              id="email"
              type="email"
              placeholder="Email"
              v-model="email"
              :state="valid_email"
              autocomplete="username"
            />
            <b-form-invalid-feedback :state="valid_email">
              Enter a valid email
            </b-form-invalid-feedback>
          </b-form-group>

          <b-form-group v-if="!forgotPass">
            <b-input
              @keyup.enter="login"
              data-hj-whitelist
              type="password"
              id="password"
              placeholder="Password"
              v-model="password"
              :state="valid_password"
              autocomplete="current-password"
            />
            <b-form-invalid-feedback :state="valid_password">
              Enter a valid password
            </b-form-invalid-feedback>
          </b-form-group>
          <b-form-group class="mb-3">
            <div class="custom-control custom-checkbox">
              <input
                type="checkbox"
                class="custom-control-input"
                id="checkbox-signin"
                checked=""
              />
              <label class="custom-control-label" for="checkbox-signin"
                >Remember me</label
              >
            </div>
          </b-form-group>
          <b-form-group class="text-center">
            <b-button
              variant="btn btn-primary btn-block"
              block
              @click="login"
              id="login"
              >Login</b-button
            >
          </b-form-group>
          <div class="text-center">
            <p class="mb-1">
              Dont have an account?
              <a href="#" @click="toggleSignup" id="signup">Sign Up</a>
            </p>
            <p class="text-muted">
              <a href="#" @click="toggleForgotPass">Forgotten Password?</a>
            </p>
          </div>
        </div>
      </b-form>
    </template>
  </s-container>
</template>

<script>
/* eslint no-unused-vars: "off" */
import { db, auth } from "@/lib/firebase.js"
import jQuery from "jquery"
import countriesTimezone from "@/lib/countriesTimezone"
import CountrySelect from "@/components/CountrySelect"
import * as constants from "@shared/constants.js"
import text_utils from "@/lib/text_utils"
import config from "@/lib/config.js"
import { isMobile } from "mobile-device-detect"
import { countries } from "country-data"

function get_country_code() {
  return new Promise((resolve, reject) => {
    const trace = []
    jQuery.ajax("/cdn-cgi/trace", {
      success: function (response) {
        const lines = response.split("\n")
        let keyValue
        for (let index = 0; index < lines.length; index++) {
          const line = lines[index]
          keyValue = line.split("=")
          trace[keyValue[0]] = decodeURIComponent(keyValue[1] || "")
          if (keyValue[0] === "loc" && trace.loc !== "XX") {
            return resolve(trace.loc)
          }
        }
      },
      error: function () {
        return reject(trace)
      },
    })
  })
}

function set_country(country) {
  this.country = country
}

export default {
  name: "Login",
  props: {
    logged_in: {
      type: Boolean,
    },
  },
  components: {
    CountrySelect,
  },
  data() {
    return {
      isMobile: isMobile,
      email: "",
      full_name: "",
      phone: "",
      valid_email: undefined,
      password: "",
      valid_password: undefined,
      confirm: "",
      signupForm: false,
      forgotPass: false,
      country: "GB",
      address: {},
      tnc_accepted: false,
    }
  },
  computed: {
    currency: function () {
      const country_info = countries[this.country.toUpperCase()]

      if (country_info.currencies.indexOf("USD") > -1) {
        return "usd"
      } else if (country_info.currencies.indexOf("GBP") > -1) {
        return "gbp"
      } else if (country_info.currencies.indexOf("EUR") > -1) {
        return "eur"
      } else {
        return "usd"
      }
    },
    header_text() {
      if (this.signupForm) {
        return "Sign Up"
      }
      if (this.forgotPass) {
        return "Forgot Password"
      }
      return "Sign In"
    },
  },
  methods: {
    submit(evt) {
      evt.preventDefault()

      if (this.signupForm) {
        this.signup()
      } else if (this.forgotPass) {
        this.forgottonPassword()
      } else {
        this.login()
      }
    },
    async login() {
      this.valid_password = undefined
      this.valid_email = undefined

      try {
        const user = await auth.signInWithEmailAndPassword(this.email, this.password)

        this.$gtm.trackEvent({
          event: "user_sign_in",
          user_id: user.uid,
        })
      } catch (error) {
        const errorCode = error.code
        if (errorCode === "auth/invalid-email") {
          this.valid_email = false
        } else {
          this.$swal("Error", error.message, "error")
        }
      }
    },
    async signup() {
      this.valid_password = undefined
      this.valid_email = undefined

      if (this.full_name.length < 1) {
        this.$swal("Error", "Please enter your name", "error")
        return
      }
      if (!this.tnc_accepted) {
        this.$swal("Error", "Please accept terms and conditions", "error")
        return
      }
      const email = (this.email || "").toLowerCase()

      try {
        const user = await auth.createUserWithEmailAndPassword(email, this.password)

        // TODO

        const uid = user.user.uid

        this.full_name = text_utils.to_title_case(this.full_name).trim()
        const full_name = this.full_name.split(" ")
        const first_name = full_name.shift()
        let last_name = full_name.join(" ")

        if (first_name === last_name) {
          last_name = ""
        }

        const api_key = `${user.user.uid}$${db.collection("users").doc().id}`

        let currency_symbol = "£"
        if (this.currency === "gbp") {
          currency_symbol = "£"
        } else if (this.currency === "usd") {
          currency_symbol = "$"
        }
        if (this.currency === "eur") {
          currency_symbol = "€"
        }

        let user_region = "EU"

        for (const region in constants.REGIONS) {
          if (constants.REGIONS[region].includes(this.country)) {
            user_region = region
          }
        }

        const user_profile = {
          auto_discard_held: false,
          auto_pay_held: false,
          email: email,
          samples_left: 0,
          country: this.country,
          currency: this.currency,
          currency_symbol: currency_symbol,
          full_name: this.full_name,
          first_name: first_name,
          last_name: last_name,
          phone: null,
          uid: uid,
          onboarding_complete: true,
          email_verified: false,
          billing_enabled: false,
          card_on_file: false,
          api_key: api_key,
          created: new Date(),
          last_updated: new Date(),
          address: {
            first_name: first_name,
            last_name: last_name,
            country: this.country,
          },
          on_demand: {
            printers: {},
            notes_used_this_month: 0,
          },
          products_enabled: [constants.PRODUCT.full_service],
          region: user_region,
          timezone_gmt_difference: countriesTimezone[this.country],
          stationery_enabled: [],
          handwriting_styles_enabled: [],
          tnc_accepted: this.tnc_accepted,
          admin: false,
          stripe_invoice_email: email,
          stripe_invoice_address: {
            "address line 1": null,
            city: null,
            country: null,
            postal_code: null,
          },
        }

        await db.collection("users").doc(user.user.uid).set(user_profile, { merge: true })

        auth.currentUser.updateProfile({
          displayName: this.full_name,
        })

        this.$gtm.trackEvent({
          event: "user_sign_up",
          user_id: auth.currentUser.uid,
          email: user_profile.email,
          display_name: user_profile.full_name,
        })

        window._is_new_user = true
      } catch (error) {
        const errorCode = error.code
        if (
          errorCode === "auth/user-disabled" ||
          errorCode === "auth/wrong-password" ||
          errorCode === "auth/user-not-found"
        ) {
          this.valid_password = false
        } else if (errorCode === "auth/invalid-email") {
          this.valid_email = false
        } else {
          this.$swal("Error", error.message, "error")
        }
      }
    },
    async forgottonPassword() {
      try {
        await auth.sendPasswordResetEmail(this.email)
        this.$swal("Success", "Password reset email sent, please check your inbox", "success")
        this.toggleForgotPass()
      } catch (err) {
        this.$swal("Error", "Email not found", "error")
        console.log("email not found", err)
      }
    },
    toggleSignup() {
      this.valid_password = undefined
      this.valid_email = undefined
      this.signupForm = !this.signupForm
    },
    toggleForgotPass() {
      this.valid_password = undefined
      this.valid_email = undefined
      this.forgotPass = !this.forgotPass
    },
  },
  created() {
    // if (navigator && navigator.language && navigator.language.split('-').length === 2 && countries[navigator.language.split('-')[1].toUpperCase()]) {
    //   let country_info = countries[navigator.language.split('-')[1].toUpperCase()]
    //   this.country = country_info.alpha2
    // }
    get_country_code()
      .then(set_country.bind(this))
      .catch((err) => {
      })

    // Confirm the link is a sign-in with email link.
    if (auth.isSignInWithEmailLink(window.location.href)) {
      const email = window.prompt("Please provide the email of user for confirmation")
      // The client SDK will parse the code from the link for you.
      auth.signInWithEmailLink(email, window.location.href)
    }
  },
  // mounted() {
  //   document.querySelector('body').style.overflow = 'hidden'
  // },
  // beforeDestroy() {
  //   document.querySelector('body').style.overflow = 'auto'
  // }
}
</script>

<style lang="scss">
.login-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}
.logo-left-container {
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.login-logo-right-container {
  display: none;
}
.login-logo-left {
  width: 40%;
}
@media (max-width: 991px) {
  .login-logo-left {
    width: 70%;
  }
}
@media (max-width: 767px) {
  .login-logo-right-container {
    display: inherit;
  }
  .header-text {
    font-size: 2.2rem;
  }
}

.modal-body {
  padding: 0px !important;
  margin: 0px !important;
}

.modal-open {
  padding: 0 !important;
}

.form {
  margin: 1em;
}

body {
  padding: 0;
}
</style>
