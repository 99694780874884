const CREDIT_SOURCE = {
  subscription: "Subscription",
  admin: "Admin",
  campaign: "Campaign",
  one_off: "One Off",
}

const CAMPAIGN_SOURCE = {
  interface: "interface",
  api_crm: "api_crm",
}

const CREDIT_STATUS = {
  active: "Active",
  expired: "Expired",
  refunded: "Refunded",
  used: "Used",
  unpaid: "Unpaid",
  ignored: "Ignored",
}

const SUBSCRIPTION_STATUS = {
  awaiting_first_payment: "Awaiting First Payment",
  awaiting_payment: "Awaiting Payment",
  awaiting_refund_confirmation: "Awaiting Refund Confirmation",
  active_paid_advance: "Paid for Next Period",
  active: "Active",
  cancelled: "Cancelled",
  upgraded: "Upgraded",
  downgraded: "Downgraded",
}

const INDUSTRY_TYPES = {
  ecommerce: "eCommerce & Retail",
  real_estate: "Real Estate",
  financial_services: "Financial Services",
  other: "Something Else",
}

const HANDWRITING_HORIZONTAL_ALIGNMENT = {
  left: 'handwriting-left',
  center: 'handwriting-centre',
  right: 'handwriting-right'
}

const USAGE_QUESTIONS = {
  fs: "I want Scribeless to send notes in envelopes directly to my customers",
  od_personalized: "I want to print personalized notes to add to orders",
  od_gift_notes: "I want to print gift notes to include in gift orders",
  other: "I'm not sure, or I want something else",
}

const GENERIC_EMAILS = [
  "gmail",
  "yahoo",
  "hotmail",
  "aol",
  "msn",
  "orange",
  "live",
  "rediffmail",
  "yandex",
  "protonmail",
  "ymail",
  "outlook",
  "mail",
  "verizon",
  "rocketmail",
]

const GOOD_TYPE = {
  subscription: "Subscription",
  product: "Product",
  stamp: "Stamp",
  delivery: "Delivery",
  paper: "Paper",
  envelope: "Envelope",
}

const SUBSCRIPTION_GOOD = {
  on_demand_note_subscription: "On Demand Note Subscription",
  full_service_pack_subscription: "Full Service Pack Subscription",
}

const SUBSCRIPTION_UPGRADE_GOOD = {
  subscription_upgrade: "Subscription Upgrade Refund",
}

const PRODUCT_GOOD = {
  full_service_pack: "Full Service Pack",
  full_service_note_only: "Full Service Note",
  full_service_envelope_only: "Full Service Envelope",
  on_demand_note_only: "On Demand Note",
  on_demand_note_only_draft: "On Demand Note Draft",
  on_demand_envelope_only: "On Demand Envelope",
  on_demand_pack: "One Demand Pack",
}

const STAMP_GOOD = {
  gb_first_class: "Stamp GB First Class",
  gb_second_class: "Stamp GB Second Class",
  gb_europe: "Stamp GB Europe",
  gb_world_zone_1: "Stamp GB World Zone 1",
  gb_world_zone_2: "Stamp GB World Zone 2",
  us_international: "Stamp US International",
  us_first_class: "Stamp US First Class",
  ca_first_class: "Stamp CA First Class",
  ca_international: "Stamp CA International",
}

const DELIVERY_GOOD = {
  ship_to_self_us: "Ship to self (US)",
  ship_to_self_gb: "Ship to self (GB)",
  ship_to_self_ca: "Ship to self (CA)",
  ship_to_self_int: "Ship to self (International)",
}

const ACCOUNT_VERIFICATION_EMAIL = {
  from: "team@scribeless.co",
  sendgrid_template_Id: "d-986d3c7c83344835bf1862e44b308884",
}

const SUBSCRIPTION_GOOD_MAPPING = {
  [SUBSCRIPTION_GOOD.on_demand_note_subscription]: PRODUCT_GOOD.on_demand_note_only,
  [SUBSCRIPTION_GOOD.full_service_pack_subscription]: PRODUCT_GOOD.full_service_pack,
}

const SUBSCRIPTION_GOOD_TYPE_MAPPING = Object.values(SUBSCRIPTION_GOOD).map((val) => ({
  [val]: GOOD_TYPE.subscription,
}))
const PRODUCT_GOOD_TYPE_MAPPING = Object.values(PRODUCT_GOOD).map((val) => ({
  [val]: GOOD_TYPE.product,
}))
const STAMP_GOOD_TYPE_MAPPING = Object.values(STAMP_GOOD).map((val) => ({
  [val]: GOOD_TYPE.stamp,
}))
const DELIVERY_GOOD_TYPE_MAPPING = Object.values(DELIVERY_GOOD).map((val) => ({
  [val]: GOOD_TYPE.delivery,
}))

const GOOD_TYPE_MAPPINGS_ARRAY = SUBSCRIPTION_GOOD_TYPE_MAPPING.concat(
  PRODUCT_GOOD_TYPE_MAPPING,
  STAMP_GOOD_TYPE_MAPPING,
  DELIVERY_GOOD_TYPE_MAPPING
)
const GOOD_TYPE_MAPPING = Object.assign({}, ...GOOD_TYPE_MAPPINGS_ARRAY)

const NON_SUBSCRIPTION_GOOD = {
  ...PRODUCT_GOOD,
  ...STAMP_GOOD,
  ...DELIVERY_GOOD,
}

const GOOD = {
  ...SUBSCRIPTION_GOOD,
  ...SUBSCRIPTION_UPGRADE_GOOD,
  ...PRODUCT_GOOD,
  ...STAMP_GOOD,
  ...DELIVERY_GOOD,
}

const USER_GOOD_MAPPING = {}

Object.entries(GOOD).forEach((obj) => {
  USER_GOOD_MAPPING[obj[1]] = obj[1]
})

USER_GOOD_MAPPING[GOOD.on_demand_note_subscription] = "Print Yourself Subscription"
USER_GOOD_MAPPING[GOOD.full_service_pack_subscription] = "Direct Mail Subscription"
USER_GOOD_MAPPING[GOOD.full_service_pack] = "Scribeless Printed Note and Envelope"
USER_GOOD_MAPPING[GOOD.full_service_note_only] = "Scribeless Printed Note"
USER_GOOD_MAPPING[GOOD.full_service_envelope_only] = "Scribeless Printed Envelope"
USER_GOOD_MAPPING[GOOD.on_demand_note_only] = "Self Printed Note"
USER_GOOD_MAPPING[GOOD.on_demand_envelope_only] = "Self Printed Envelope"
USER_GOOD_MAPPING[GOOD.on_demand_pack] = "Self Printed Note and Envelope"

const SENDER = {
  ship_for_me: "Ship for me",
  ship_to_self: "Ship to self",
  download_files: "Download Files",
  print_on_request: "Print on Request",
  print_now: "Print Now",
}

const SENDER_MAPPING = {}

SENDER_MAPPING[SENDER.ship_for_me] = "Mailed"
SENDER_MAPPING[SENDER.ship_to_self] = "Tracked Delivery"
SENDER_MAPPING[SENDER.download_files] = "Download Files"
SENDER_MAPPING[SENDER.print_on_request] = "Triggered Print"
SENDER_MAPPING[SENDER.print_now] = "Print Now"

const EUROPE_VAT_COUNTRIES = [
  "BE",
  "BG",
  "HR",
  "CZ",
  "DK",
  "DE",
  "EE",
  "IE",
  "GR",
  "ES",
  "FR",
  "IT",
  "CY",
  "LV",
  "LT",
  "LU",
  "HU",
  "MT",
  "NL",
  "AT",
  "PL",
  "PT",
  "RO",
  "SI",
  "SK",
  "FI",
  "SE",
  "GB",
]

const TAX_RATE = {
  gb_20_vat: "GB 20% VAT",
  gb_0_vat: "GB 0% VAT",
  outside_eu_0_vat: "Outside Europe 0% VAT",
}

let currency = {
  gbp_to_usd: 1.35,
  gbp_to_eur: 1.15,
  gbp_to_cad: 1.7,
}
currency.usd_to_gbp = 1 / currency.gbp_to_usd
currency.eur_to_gbp = 1 / currency.gbp_to_eur
currency.cad_to_gbp = 1 / currency.gbp_to_cad
currency.usd_to_eur = currency.gbp_to_eur / currency.gbp_to_usd
currency.eur_to_usd = currency.gbp_to_usd / currency.gbp_to_eur
currency.cad_to_eur = currency.gbp_to_eur / currency.gbp_to_cad
currency.cad_to_usd = currency.gbp_to_usd / currency.gbp_to_cad

const CURRENCY_SYMBOLS = {
  gbp: "£",
  usd: "$",
  eur: "€",
  cad: "$",
}

const CURRENCY_CODE = {
  gbp: "gbp",
  usd: "usd",
  eur: "eur",
  cad: "cad",
}

const SERVICEABLE_BLEED_STATUSES = {
  mandatory: "Mandatory Bleeds",
  none: "No Bleeds",
  adaptable: "Bleeds When Needed",
}

const FILE_TRANSFER_METHOD = {
  api: "api",
  email: "email",
}

const PRINTHOUSE_OUTWARD_EMAIL = "operations@scribeless.co"

const ERROR = {
  sample_campaign: "error/sample-campaign",
  field_required: "error/field-required",
  invalid_status: "error/invalid-status",
  invalid_key: "error/invalid-key",
  character_not_supported: "error/character-not-supported",
  payment_method_required: "error/payment-method-required",
  invalid_date: "error/invalid-date",
  invalid_handwriting_style: "error/invalid-handwriting-style",
  invalid_font: "error/invalid-font",
  invalid_paper_size: "error/invalid-paper-size",
  invalid_product: "error/invalid-product",
  unauthorised: "error/unauthorised",
  not_found: "error/not-found",
}

const RECIPIENT_SOURCE = {
  hubspot: "HubSpot",
  shopify: "Shopify",
  shopify_flow: "Shopify Flow",
  interface: "Interface",
  api: "API",
  admin: "Admin",
  system: "System",
  zapier: "Zapier",
  sheets: "Google Sheets",
  unknown: "Unknown",
}

const RECIPIENT_FIELDS_ORDER = [
  "title",
  "first name",
  "last name",
  "department",
  "company",
  "address line 1",
  "address line 2",
  "address line 3",
  "city",
  "postal_code",
  "region",
  "country",
  "custom 1",
  "custom 2",
  "custom 3",
  "custom 4",
  "custom 5",
  "custom 6",
]

const RECIPIENT_FIELDS_FULL_INFORMATION = [
  {
    field: "title",
  },
  {
    field: "first name",
    required: true,
  },
  {
    field: "last name",
  },
  {
    field: "department",
  },
  {
    field: "company",
  },
  {
    field: "address line 1",
    required: true,
  },
  {
    field: "address line 2",
  },
  {
    field: "address line 3",
  },
  {
    field: "city",
    required: true,
  },
  {
    field: "postal_code",
    isPostalCode: true,
  },
  {
    field: "region",
    label: "State/Region",
    isState: true,
  },
  {
    field: "country",
    required: true,
    isCountrySelection: true,
  },
]

const INTEGRATION_SOURCE = {
  hubspot: "HubSpot",
  shopify: "Shopify",
  zapier: "Zapier",
  rest_api: "Rest API",
}

const SUBSCRIPTION_SOURCE = {
  customer: "Customer",
  admin: "Admin",
  system: "System",
}

const USAGE_SOURCE = {
  ...RECIPIENT_SOURCE,
  ...SUBSCRIPTION_SOURCE,
}

const CAMPAIGN_PAYMENT_TYPE = {
  one_off: "One Off",
  subscription: "Subscription",
}

const GOOD_PAYMENT_TIMING = {
  immediate: "Immediate",
  over_period: "Over Period",
}

const PAYMENT_TIMING_MAPPING = {
  [CAMPAIGN_PAYMENT_TYPE.one_off]: GOOD_PAYMENT_TIMING.immediate,
  [CAMPAIGN_PAYMENT_TYPE.subscription]: GOOD_PAYMENT_TIMING.over_period,
}

const INVOICE_PAYMENT_METHOD = {
  card: "Card",
  invoice: "Invoice",
}

const STRIPE_UI_PAYMENT_METHOD = {
  checkout: "Checkout",
}

const PAYMENT_METHOD = {
  ...INVOICE_PAYMENT_METHOD,
  ...STRIPE_UI_PAYMENT_METHOD,
}

const SAMPLE_MESSAGE = `Dear {{{first name}}},

Thanks for requesting a sample to see one of our handwritten notes.

Whether you are sending to prospects, customers or ambassadors, personalize the customer experience and achieve better engagement rates.

 Kind regards,
   Scribeless`

const SAMPLE_MESSAGE_SMALL = `Dear {{{first name}}},

Thanks for requesting to see one of our handwritten notes.
Personalize your customer experience and achieve better engagement rates.

 Kind regards,
   Scribeless`

const DEFAULT_MESSAGE = `Hey {{{first name}}},

Click to add your message here`

const STATIONERY_OPTIONS = {
  printed_envelopes_only: "Printed Envelopes Only",
}

const DEFAULT_SAMPLE_FOOTER = "Scribeless Sample // Visit scribeless.co for more details"

const DEFAULT_STANDARD_FOOTER = "Type your footer text here!"

const RECIPIENT_ERRORS = {
  bad_characters: {
    type: "bad_characters",
    title: "Recipient contains unsupported characters, Please, remove them",
  },
  first_name: {
    type: "first_name",
    title: "Invalid First Name ",
    description: "First Name field should contain atleast 2 letters",
  },
  last_name: {
    type: "last_name",
    title: "Invalid Last Name",
    description: "Last Name field should contain atleast 2 letters",
  },
  address_line_1: {
    type: "address_line_1",
    title: "Invalid Address line 1",
    description: "Address line 1 should contain at least 2 characters",
  },
  city: {
    type: "city",
    title: "Invalid City",
    description: "City field should contain atleast 2 characters",
  },
  country: {
    type: "country",
    title: "Missing country field",
    description: "Country field cannot be empty",
  },
  invalid_country: {
    type: "invalid_country",
    title: "Invalid Country",
    description: "Select a valid country",
  },
  postal_code: {
    type: "postal_code",
    title: "Invalid Postal Code",
    description: "Postal Code is invalid. Please enter a valid postal code",
  },
  us_state: {
    type: "us_state",
    title: "Invalid State Code",
    description: "A two letter state is required when sending in the US",
  },
}

const PRICING_MODEL = {
  PAYG: {
    usd: {
      name: "PAYG",
      scribeless_subscription: 0,
      direct_mail: 269,
      print_on_demand: 99,
      print_on_demand_included: 0,
      billing_threshold: 50,
    },
    gbp: {
      name: "PAYG",
      scribeless_subscription: 0,
      direct_mail: 209,
      print_on_demand: 79,
      print_on_demand_included: 0,
      billing_threshold: 50,
    },
    eur: {
      name: "PAYG",
      scribeless_subscription: 0,
      direct_mail: 249,
      print_on_demand: 89,
      print_on_demand_included: 0,
      billing_threshold: 50,
    },
  },
  Starter: {
    usd: {
      name: "Starter",
      scribeless_subscription: 24900,
      direct_mail: 229,
      print_on_demand: 49,
      print_on_demand_included: 500,
      billing_threshold: null,
    },
    gbp: {
      name: "Starter",
      scribeless_subscription: 19900,
      direct_mail: 179,
      print_on_demand: 39,
      print_on_demand_included: 500,
      billing_threshold: null,
    },
    eur: {
      name: "Starter",
      scribeless_subscription: 24900,
      direct_mail: 209,
      print_on_demand: 39,
      print_on_demand_included: 500,
      billing_threshold: null,
    },
  },
  Grow: {
    usd: {
      name: "Grow",
      scribeless_subscription: 59900,
      direct_mail: 199,
      print_on_demand: 39,
      print_on_demand_included: 1500,
      billing_threshold: null,
    },
    gbp: {
      name: "Grow",
      scribeless_subscription: 44900,
      direct_mail: 159,
      print_on_demand: 29,
      print_on_demand_included: 1500,
      billing_threshold: null,
    },
    eur: {
      name: "Grow",
      scribeless_subscription: 54900,
      direct_mail: 185,
      print_on_demand: 35,
      print_on_demand_included: 1500,
      billing_threshold: null,
    },
  },
  Scale: {
    usd: {
      name: "Scale",
      scribeless_subscription: 99900,
      direct_mail: 179,
      print_on_demand: 29,
      print_on_demand_included: 3000,
      billing_threshold: null,
    },
    gbp: {
      name: "Scale",
      scribeless_subscription: 79900,
      direct_mail: 139,
      print_on_demand: 19,
      print_on_demand_included: 3000,
      billing_threshold: null,
    },
    eur: {
      name: "Scale",
      scribeless_subscription: 89900,
      direct_mail: 165,
      print_on_demand: 25,
      print_on_demand_included: 3000,
      billing_threshold: null,
    },
  },
  Custom: {},
  Legacy: {
    usd: {
      name: "Legacy",
    },
    gbp: {
      name: "Legacy",
    },
    eur: {
      name: "Legacy",
    },
  },
  Admin: {
    usd: {
      name: "Admin",
    },
    gbp: {
      name: "Admin",
    },
    eur: {
      name: "Admin",
    },
  },
}

const STRIPE_PRODUCTS = {
  scribeless_subscription: "Scribeless Subscription",
  print_on_demand: "Print On Demand",
  direct_mail: "Direct Mail",
}

// Campaign Statuses
const CAMPAIGN_STATUS_USER_VIEW = {
  pending: "Pending",
  in_progress: "In progress",
  partially_held: "Partially Held",
  ready_to_ship: "Ready to Ship",
  complete: "Complete",
  error: "Error",
  action_needed: "Action Needed",
  quality_control: "Quality Control"
}
const CAMPAIGN_STATUS = {
  ...CAMPAIGN_STATUS_USER_VIEW,
  deleted: "Deleted",
}
const CAMPAIGN_STATUS_NON_LEGACY_USER_VIEW = {
  in_progress: "In progress",
  active: "Active",
  shipped: "Shipped",
  stopped: "Stopped",
  paused: "Paused",
  error: "Error",
  admin_hold: "Admin hold",
  awaiting_card: "Waiting for card",
  awaiting_printer_setup: "Waiting for printer setup",
  quality_control: "Quality Control"
}
const CAMPAIGN_STATUS_NON_LEGACY = {
  ...CAMPAIGN_STATUS_NON_LEGACY_USER_VIEW,
  deleted: "Deleted",
}
const CAMPAIGN_STATUS_ALL = {
  ...CAMPAIGN_STATUS,
  ...CAMPAIGN_STATUS_NON_LEGACY,
}

module.exports = {
  STRIPE_PRODUCTS: STRIPE_PRODUCTS,
  PRICING_MODEL: PRICING_MODEL,
  STAMP_GOOD: STAMP_GOOD,
  INDUSTRY_TYPES: INDUSTRY_TYPES,
  HANDWRITING_HORIZONTAL_ALIGNMENT: HANDWRITING_HORIZONTAL_ALIGNMENT,
  GENERIC_EMAILS: GENERIC_EMAILS,
  USAGE_QUESTIONS: USAGE_QUESTIONS,
  CAMPAIGN_PAYMENT_TYPE: CAMPAIGN_PAYMENT_TYPE,
  SAMPLE_MESSAGE: SAMPLE_MESSAGE,
  SAMPLE_MESSAGE_SMALL: SAMPLE_MESSAGE_SMALL,
  DEFAULT_MESSAGE: DEFAULT_MESSAGE,
  BLEED: 4,
  BLEED_LARGE: 3.175,
  BLEED_MARK_MARGIN: 2,
  ERROR: ERROR,
  CURRENCY: currency,
  CURRENCY_CODE: CURRENCY_CODE,
  MIN_DELIVERY_DATE: 4,
  MIN_DISPATCH_DAYS: 1,
  BILLING_PERIOD_DAYS: 30,
  BILLING_INVOICE_DUE_DAYS: 30,
  RECIPIETS_PER_SHIP_TO_SELF: 1000,
  RECIPIENT_SOURCE: RECIPIENT_SOURCE,
  RECIPIENT_FIELDS_ORDER: RECIPIENT_FIELDS_ORDER,
  RECIPIENT_FIELDS_FULL_INFORMATION: RECIPIENT_FIELDS_FULL_INFORMATION,
  SUBSCRIPTION_SOURCE: SUBSCRIPTION_SOURCE,
  INTEGRATION_SOURCE: INTEGRATION_SOURCE,
  USAGE_SOURCE: USAGE_SOURCE,
  STATIONERY_OPTIONS: STATIONERY_OPTIONS,
  SERVICEABLE_BLEED_STATUSES: SERVICEABLE_BLEED_STATUSES,
  FILE_TRANSFER_METHOD: FILE_TRANSFER_METHOD,
  PRINTHOUSE_OUTWARD_EMAIL: PRINTHOUSE_OUTWARD_EMAIL,
  DEFAULT_SAMPLE_FOOTER: DEFAULT_SAMPLE_FOOTER,
  DEFAULT_STANDARD_FOOTER: DEFAULT_STANDARD_FOOTER,
  PAYMENT_METHOD: PAYMENT_METHOD,
  INVOICE_PAYMENT_METHOD: INVOICE_PAYMENT_METHOD,
  GOOD_PAYMENT_TIMING: GOOD_PAYMENT_TIMING,
  GOOD_TYPE: GOOD_TYPE,
  CREDIT_SOURCE: CREDIT_SOURCE,
  CREDIT_STATUS: CREDIT_STATUS,
  SUBSCRIPTION_STATUS: SUBSCRIPTION_STATUS,
  NON_SUBSCRIPTION_GOOD: NON_SUBSCRIPTION_GOOD,
  SUBSCRIPTION_GOOD: SUBSCRIPTION_GOOD,
  GOOD: GOOD,
  USER_GOOD_MAPPING: USER_GOOD_MAPPING,
  SENDER_MAPPING: SENDER_MAPPING,
  PRODUCT_GOOD: PRODUCT_GOOD,
  CURRENCY_SYMBOLS: CURRENCY_SYMBOLS,
  SUBSCRIPTION_GOOD_MAPPING: SUBSCRIPTION_GOOD_MAPPING,
  GOOD_TYPE_MAPPING: GOOD_TYPE_MAPPING,
  PAYMENT_TIMING_MAPPING: PAYMENT_TIMING_MAPPING,
  ACCOUNT_VERIFICATION_EMAIL: ACCOUNT_VERIFICATION_EMAIL,
  MINIMUM_TEXTBOX_SIZE: {
    width: 100,
    height: 115,
  },
  RETURN_OBJECTS: {
    INTERNAL_SERVER_ERROR: {
      success: false,
      error_message: "Something went wrong, Please contact the administrator",
      error_code: "error/internal-server-error",
    },
  },
  CAMPAIGN_STATUS_USER_VIEW,
  CAMPAIGN_STATUS,
  CAMPAIGN_STATUS_ALL,
  CAMPAIGN_STATUS_NON_LEGACY_USER_VIEW,
  CAMPAIGN_STATUS_NON_LEGACY,
  CAMPAIGN_STATUS_DETAILS: {
    awaiting_payment_initation: "Waiting for Payment Initiation",
    awaiting_payment: "Waiting for Payment",
    awaiting_credits: "Waiting for More Credits",
    awaiting_printer_setup: "Waiting for Printer Setup",
    awaiting_recipients: "Waiting for Recipients",
    unknown: "Unknown",
    none: "None",
  },
  RECIPIENT_STATUS: {
    pending: "Pending",
    in_progress: "AI Writing",
    ready_to_print: "Ready",
    shipped: "Shipped",
    returned: "Returned",
    received: "Received",
    lost: "Lost",
    error: "Error",
    hold: "Hold",
    deleted: "Deleted",
    review_required: "Review Required",
    ignored: "Not Sent",
  },
  RECIPIENT_OD_STATUS: {
    waiting_for_request: "Waiting for Request",
    ready_for_print_queue: "Ready for Print Queue",
    preparing_for_print_queue: "Preparing for Print Queue",
    sent_to_printer: "Sent to Printer",
    ignored: "Not Printed",
  },
  BATCH_STATUS: {
    pending: "Pending",
    shipped: "Shipped",
    error: "Error",
  },
  PRINT_QUEUE_STATUS: {
    sent_to_printer: "Sent to Printer",
    printed: "Printed",
    error: "Error",
  },
  USAGE_QUEUE_STATUS: {
    pending: "Pending",
    processed: "Processed",
    error: "Error",
  },
  NOTIFICATION_LEVEL: {
    error: "error",
    info: "info",
  },
  NOTIFICATION_STATUS: {
    new: "new",
    notified: "notified",
    dont_notify: "dont_notify",
  },
  RECIPIENTS_ERROR: {
    last_name: 0,
    address: 1,
    city: 2,
    quantity: 3,
    postcode: 4,
    country: 5,
    us_state: 6,
    unsupported_chars: 100,
    first_name: 7,
  },
  ZIPPING_ERROR_STATUS: {
    recipient_not_ready: "Recipient Not Ready",
    recipient_no_url: "Recipient No Url",
    no_zip_produced: "No Zip Produced",
  },
  TEAM_TYPES: {
    collaborators: "Collaborators",
    solo: "Solo",
  },
  TEAM_JOIN_TYPES: {
    invite: "Invite",
    public_link: "Public Link",
    scribeless_admin: "Scribeless Admin",
    creator: "Creator",
  },
  TEAM_ROLES: {
    creator: "Creator",
    admin: "Admin",
    member: "Member",
  },
  TEAM_PAYMENT_TYPES: {
    team: "Team",
    agency: "Agency",
  },
  MIN_UNITS: 1,
  MAX_UNITS: 10000,
  PRODUCTION_DAYS: 5,
  PAPER_SIZES: {
    A5_landscape: {
      name: "A5",
      key: "A5_landscape",
      width: "210",
      height: "148",
      orientation: "landscape",
      envelope: "C5",
      us_standard: "half_letter_landscape",
      scale: 0.84,
      left: 4,
      top: 0,
      iso_standard: null,
      scale_back: 0.86,
      back_left: 0,
      handwriting_image_size_override: 350,
      regions: ["EU"],
      default_sample_size_regions: ["EU"],
    },
    A4: {
      name: "A4",
      key: "A4",
      width: "210",
      height: "297",
      orientation: "portrait",
      envelope: "C5",
      us_standard: "letter",
      scale: 0.84,
      left: 4,
      top: 0,
      iso_standard: null,
      scale_back: 0.86,
      back_left: 0,
      handwriting_image_size_override: 800,
      regions: ["EU"],
    },
    A4_landscape: {
      name: "A4",
      key: "A4_landscape",
      width: "297",
      height: "210",
      orientation: "landscape",
      envelope: "C5",
      us_standard: "letter_landscape",
      scale: 0.84,
      left: 4,
      top: 0,
      iso_standard: null,
      scale_back: 0.86,
      back_left: 0,
      handwriting_image_size_override: 800,
      regions: ["EU"],
    },
    A5: {
      name: "A5",
      key: "A5",
      width: "148",
      height: "210",
      orientation: "portrait",
      envelope: "C5",
      us_standard: "half_letter",
      scale: 0.84,
      left: 4,
      top: 0,
      iso_standard: null,
      scale_back: 0.86,
      back_left: 0,
      handwriting_image_size_override: 530,
      regions: ["EU"],
    },
    A6_landscape: {
      name: "A6",
      key: "A6_landscape",
      width: "148",
      height: "105",
      orientation: "landscape",
      envelope: "C6",
      us_standard: "postcard_landscape",
      scale: 0.97,
      top: 0,
      left: 0,
      iso_standard: null,
      scale_back: 0.98,
      back_left: 4,
      regions: ["EU"],
    },
    A6: {
      name: "A6",
      key: "A6",
      width: "105",
      height: "148",
      orientation: "portrait",
      envelope: "C6",
      us_standard: "postcard",
      scale: 0.97,
      top: 3,
      left: 0,
      iso_standard: null,
      scale_back: 0.98,
      back_left: 0,
      regions: ["EU"],
    },
    half_letter_landscape: {
      name: "Half Letter",
      key: "half_letter_landscape",
      width: "177.8",
      height: "127",
      orientation: "landscape",
      envelope: "NAS_A7",
      iso_standard: "A5_landscape",
      scale: 1.16,
      scale_back: 1.16,
      left: 0,
      top: 0,
      us_standard: null,
      back_left: 0,
      regions: ["ROW"],
      default_sample_size_regions: ["ROW"],
    },
    half_letter: {
      name: "Half Letter",
      key: "half_letter",
      width: "127",
      height: "177.8",
      orientation: "portrait",
      envelope: "NAS_A7",
      iso_standard: "A5",
      scale: 1.16,
      left: 0,
      top: 0,
      us_standard: null,
      scale_back: 1.16,
      back_left: 0,
      regions: ["ROW"],
    },
    letter: {
      name: "Letter",
      key: "letter",
      width: "177.8",
      height: "254",
      orientation: "portrait",
      envelope: "NAS_A7",
      iso_standard: "A4",
      scale: 1.16,
      left: 0,
      top: 0,
      us_standard: null,
      scale_back: 1.16,
      back_left: 0,
      regions: ["ROW"],
    },
    letter_landscape: {
      name: "Letter",
      key: "letter_landscape",
      width: "254",
      height: "177.8",
      orientation: "landscape",
      envelope: "NAS_A7",
      iso_standard: "A4_landscape",
      scale: 1.16,
      left: 0,
      top: 0,
      us_standard: null,
      scale_back: 1.16,
      back_left: 0,
      regions: ["ROW"],
    },
    postcard_landscape: {
      name: "Postcard",
      key: "postcard_landscape",
      width: "152.4",
      height: "101.6",
      orientation: "landscape",
      envelope: "NAS_A4",
      iso_standard: "A6_landscape",
      scale: 0.97,
      left: 0,
      top: 0,
      us_standard: null,
      scale_back: 0.99,
      back_left: 0,
      regions: ["ROW"],
    },
    postcard: {
      name: "Postcard",
      key: "postcard",
      width: "101.6",
      height: "152.4",
      orientation: "portrait",
      envelope: "NAS_A4",
      iso_standard: "A6",
      scale: 0.97,
      left: 0,
      top: 0,
      us_standard: null,
      scale_back: 1.02,
      back_left: 0,
      regions: ["ROW"],
    },
    A7_landscape: {
      name: "A7",
      key: "A7_landscape",
      width: "105",
      height: "74",
      orientation: "landscape",
      envelope: null,
      scale: 0.97,
      top: 0,
      left: 0,
      iso_standard: null,
      scale_back: 0.98,
      back_left: 0,
    },
    "150x100": {
      name: "150x100",
      key: "150x100",
      width: "150",
      height: "100",
      orientation: "landscape",
      envelope: null,
      iso_standard: null,
      scale: 0.97,
      left: 0,
      top: 0,
      us_standard: null,
      scale_back: 1.02,
      back_left: 0,
    },
    "160x100": {
      name: "160x100",
      key: "160x100",
      width: "160",
      height: "100",
      orientation: "landscape",
      envelope: null,
      iso_standard: null,
      scale: 0.97,
      left: 0,
      top: 0,
      us_standard: null,
      scale_back: 1.02,
      back_left: 0,
    },
    "6x6": {
      name: "6x6",
      key: "6x6",
      width: "152.4",
      height: "152.4",
      orientation: "portrait",
      envelope: "C5",
      iso_standard: null,
      scale: 0.97,
      left: 0,
      top: 0,
      us_standard: null,
      scale_back: 1.02,
      back_left: 0,
    },
    "5x5": {
      name: "5x5",
      key: "5x5",
      width: "127",
      height: "127",
      orientation: "portrait",
      envelope: "C5",
      iso_standard: null,
      scale: 0.97,
      left: 0,
      top: 0,
      us_standard: null,
      scale_back: 1.02,
      back_left: 0,
    },
    "5.5x5.5": {
      name: "5.5x5.5",
      key: "5.5x5.5",
      width: "139.7",
      height: "139.7",
      orientation: "portrait",
      envelope: "C5",
      iso_standard: null,
      scale: 0.97,
      left: 0,
      top: 0,
      us_standard: null,
      scale_back: 1.02,
      back_left: 0,
    },
    "5x7": {
      name: "5x7",
      key: "5x7",
      width: "127",
      height: "177.8",
      orientation: "portrait",
      envelope: "C5",
      iso_standard: null,
      scale: 0.97,
      left: 0,
      top: 0,
      us_standard: null,
      scale_back: 1.02,
      back_left: 0,
    },
    "5x7_landscape": {
      name: "5x7",
      key: "5x7_landscape",
      width: "177.8",
      height: "127",
      orientation: "landscape",
      envelope: "C5",
      iso_standard: null,
      scale: 0.97,
      left: 0,
      top: 0,
      us_standard: null,
      scale_back: 1.02,
      back_left: 0,
    },
    

    // ? Greetings Cards
    // - UK
    A4_greetings_card: {
      name: "Greetings Card (A4)",
      key: "A4_greetings_card",
      width: "297",
      height: "210",
      orientation: "landscape",
      envelope: "C5",
      us_standard: "letter_greetings_card",
      scale: 0.84,
      left: 4,
      top: 0,
      iso_standard: null,
      scale_back: 0.86,
      back_left: 0,
      handwriting_image_size_override: 800,
      greetings_card: true,
      regions: ["EU"],
    },
    A4_greetings_card_landscape: {
      name: "Greetings Card (A4)",
      key: "A4_greetings_card_landscape",
      width: "210",
      height: "297",
      orientation: "portrait",
      envelope: "C5",
      us_standard: "letter_greetings_card",
      scale: 0.84,
      left: 0,
      top: 0,
      iso_standard: null,
      scale_back: 0.86,
      back_left: 0,
      handwriting_image_size_override: 800,
      greetings_card: true,
      regions: ["EU"],
    },
    // - US
    letter_greetings_card: {
      name: "Greetings Card (Letter)",
      key: "letter_greetings_card",
      width: "252.35",
      height: "176.15",
      orientation: "landscape",
      envelope: "NAS_A7",
      iso_standard: "A4_greetings_card",
      scale: 1.16,
      left: 0,
      top: 0,
      us_standard: null,
      scale_back: 1.16,
      back_left: 0,
      greetings_card: true,
      regions: ["ROW"],
    },
    letter_greetings_card_landscape: {
      name: "Greetings Card (Letter)",
      key: "letter_greetings_card_landscape",
      width: "177.8",
      height: "254",
      orientation: "portrait",
      envelope: "NAS_A7",
      iso_standard: "A4_greetings_card",
      scale: 1.16,
      left: 0,
      top: 0,
      us_standard: null,
      scale_back: 1.16,
      back_left: 0,
      greetings_card: true,
      regions: ["ROW"],
    },
  },
  ENVELOPE_SIZES: {
    DL: {
      name: "DL",
      key: "DL",
      width: "220",
      height: "110",
      us_standard: null,
      iso_standard: null,
    },
    C5: {
      name: "C5",
      key: "C5",
      width: "229",
      height: "162",
      us_standard: "NAS_A7",
      iso_standard: null,
      paper: "A5_landscape",
      regions: ["EU"],
      default_regions: ["EU"],
    },
    C6: {
      name: "C6",
      key: "C6",
      width: "162",
      height: "114",
      us_standard: "NAS_A4",
      iso_standard: null,
      paper: "A6_landscape",
      regions: ["EU"],
    },
    NAS_A7: {
      name: "A7",
      key: "NAS_A7",
      width: "184.15",
      height: "133.35",
      us_standard: null,
      iso_standard: "C5",
      paper: "half_letter_landscape",
      regions: ["ROW"],
      default_regions: ["ROW"],
    },
    // NAS_A7_DW: {
    //   name: "A7 - DirectWorx",
    //   key: "NAS_A7_DW",
    //   width: "190.5",
    //   height: "133.35",
    //   us_standard: null,
    //   iso_standard: "C5",
    //   paper: "half_letter_landscape",
    //   regions: ["ROW"],
    //   default_regions: ["ROW"],
    // },
    NAS_A4: {
      name: "A4",
      key: "NAS_A4",
      width: "165.1",
      height: "120.65",
      us_standard: null,
      iso_standard: "C6",
      paper: "postcard_landscape",
      regions: ["ROW"],
    },
    Sticker: {
      name: "Sticker",
      key: "Sticker",
      width: "99",
      height: "38",
      us_standard: null,
      iso_standard: null,
    },
  },
  REGIONS: {
    EU: [
      "GB",
      "IE",
      "GG",
      "IM",
      "AL",
      "AD",
      "AM",
      "AT",
      "AZ",
      "BY",
      "BE",
      "BA",
      "BG",
      "HR",
      "CY",
      "CZ",
      "DK",
      "EE",
      "FO",
      "FI",
      "FR",
      "GE",
      "DE",
      "GI",
      "GR",
      "GL",
      "HU",
      "IS",
      "IT",
      "JE",
      "KZ",
      "XK",
      "KG",
      "LV",
      "LI",
      "LT",
      "LU",
      "MK",
      "MT",
      "MD",
      "MC",
      "ME",
      "NL",
      "NO",
      "PL",
      "PT",
      "RO",
      "RU",
      "SM",
      "RS",
      "SK",
      "SI",
      "ES",
      "SE",
      "CH",
      "TJ",
      "TR",
      "TM",
      "UA",
      "UZ",
      "VA",
    ],
    ROW: [
      "US",
      "CA",
      "AU",
      "IO",
      "CX",
      "CC",
      "CK",
      "FJ",
      "PF",
      "TF",
      "KI",
      "MO",
      "NR",
      "NC",
      "NZ",
      "NU",
      "NF",
      "MP",
      "PG",
      "LA",
      "PN",
      "SG",
      "SB",
      "TK",
      "TO",
      "TV",
      "AS",
      "WS",
      "GS",
      "IE",
      "MX",
      "AF",
      "AX",
      "DZ",
      "AO",
      "AI",
      "AQ",
      "AG",
      "AR",
      "AW",
      "BS",
      "BH",
      "BD",
      "BB",
      "BZ",
      "BJ",
      "BM",
      "BT",
      "BO",
      "BW",
      "BV",
      "BR",
      "BN",
      "BF",
      "BI",
      "KH",
      "CM",
      "CV",
      "KY",
      "CF",
      "TD",
      "CL",
      "CN",
      "CO",
      "KM",
      "CG",
      "CD",
      "CR",
      "CI",
      "CU",
      "DJ",
      "DM",
      "DO",
      "EC",
      "EG",
      "SV",
      "GQ",
      "ER",
      "ET",
      "FK",
      "GF",
      "GA",
      "GM",
      "GH",
      "GD",
      "GP",
      "GU",
      "GT",
      "GN",
      "GW",
      "GY",
      "HT",
      "HM",
      "HN",
      "HK",
      "IN",
      "ID",
      "IR",
      "IQ",
      "IL",
      "JM",
      "JP",
      "JO",
      "KE",
      "KP",
      "KR",
      "KW",
      "LB",
      "LS",
      "LR",
      "LY",
      "MG",
      "MW",
      "MY",
      "MV",
      "ML",
      "MH",
      "MQ",
      "MR",
      "MU",
      "YT",
      "FM",
      "MN",
      "MS",
      "MA",
      "MZ",
      "MM",
      "NA",
      "NP",
      "AN",
      "NI",
      "NE",
      "NG",
      "OM",
      "PK",
      "PW",
      "PS",
      "PA",
      "PY",
      "PE",
      "PH",
      "PR",
      "QA",
      "RE",
      "RW",
      "SH",
      "KN",
      "LC",
      "PM",
      "VC",
      "ST",
      "SA",
      "SN",
      "SC",
      "SL",
      "SO",
      "ZA",
      "LK",
      "SD",
      "SR",
      "SJ",
      "SZ",
      "SY",
      "TW",
      "TZ",
      "TH",
      "TL",
      "TG",
      "TT",
      "TN",
      "TC",
      "UG",
      "AE",
      "UM",
      "UY",
      "VU",
      "VE",
      "VN",
      "VG",
      "VI",
      "WF",
      "EH",
      "YE",
      "ZM",
      "ZW",
    ],
  },
  IMPERIAL_UNIT_COUNTRIES: ["US"],
  HANDWRITING_SIZE: {
    6: "Small",
    7: "Medium",
    8: "Large",
  },
  INVOICE_STATUS: {
    draft: "draft",
    open: "open",
    paid: "paid",
    uncollectible: "uncollectible",
    void: "void",
  },
  //TODO: Uncomment Jane after adding Jane font from API
  HANDWRITING_STYLE: {
    Stafford: "Stafford",
    George: "George", // Whitewell
    // 'Jane': 'Jane', // Autumn 5WGWVZ1G00XB

    Ernest: "Ernest", // Barrow
    Mark: "Mark", // Selfridge
    Virginia: "Virginia", // Thama
    William: "William", // Hampton

    new_Lytle: "Lytle",
    new_Parker: "Parker",
    new_Usther: "Usther",
    new_Williams: "Williams",
    new_Kunjar: "Kunjar",
    new_Stafford: "Stafford",
    new_Tremblay: "Tremblay",
    new_Ginsberg: "Ginsberg",
    new_George: "George",
    new_Foster: "Foster",

    Whitman: "Whitman", // Autumn, 5WGWVZ1G00XB
    Dickinson: "Dickinson", // Irwin, 8X3WQ64R00B2
    Plath: "Plath", // Abbey, 8X3WQ4D800B0
    Ginsberg: "Ginsberg", // Lafayette, 5WGWVY9R00WY
    Emerson: "Emerson", // Dumont, 8X3WPWHG00AQ

    Peyton: "Peyton",
    Easley: "Easley",
    Lytle: "Lytle",
    Parker: "Parker",
    Brady: "Brady",
    Sawdon: "Sawdon",
    Sutton: "Sutton",
    Tremblay: "Tremblay",
    Abrahim: "Abrahim",
    Mabus: "Mabus",

    Simon: "Simon",
    Freud: "Freud",
    Sellers: "Sellers",
    Chaplin: "Chaplin",
    Robinson: "Robinson",
    Thomas: "Thomas",
  },
  FONTS: {
    josefin: "Josefin Sans",
    playfair: "Playfair Display",
    poiret: "Poiret One",
    "Josefin Sans": "Josefin Sans",
    "Playfair Display": "Playfair Display",
    "Poiret One": "Poiret One",
  },
  FEATURE_FLAGS: {
    back_stationery_html_editor: "back_stationery_html_editor",
    agency_team: "Agency Team",
    invoicing_enabled: "Invoicing Enabled",
  },
  HEADER_TYPE: {
    text: "Text",
    logo: "Logo",
    none: "None",
  },
  FOOTER_TYPE: {
    editable: "Editable",
    static: "Static",
    none: "None",
  },
  STAMP_TYPES: {
    first_class: "First Class",
    no_stamp: "No Stamp",
  },
  DATA_SOURCE: {
    upload: "Upload",
    api_crm: "API / CRM",
    audience: "Audience",
  },
  ENVELOPE_REQUIRED: {
    yes: "Envelope",
    no: "No Envelope",
  },
  SENDER: SENDER,
  DELAY_DELIVERY_DAYS: {
    0: "0 days",
    1: "1 day",
    2: "2 days",
    3: "3 days",
    4: "4 days",
    5: "5 days",
    6: "6 days",
    7: "7 days",
    8: "8 days",
    9: "9 days",
    10: "10 days",
    11: "11 days",
    12: "12 days",
    13: "13 days",
    14: "14 days",
    15: "15 days",
    16: "16 days",
    17: "17 days",
    18: "18 days",
    19: "19 days",
    20: "20 days",
  },
  PRODUCT: {
    on_demand: "On Demand",
    full_service: "Full Service",
  },
  PRODUCT_PLAN_MAPPING: {
    full_service_pack: "direct_mail",
    full_service_note_only: "direct_mail",
    full_service_envelope_only: "direct_mail",
    on_demand_note_only: "print_on_demand",
    on_demand_note_only_draft: "print_on_demand",
    on_demand_envelope_only: "print_on_demand",
    on_demand_pack: "print_on_demand",
  },
  GREETINGS_CARD: {
    is_dashboard_cta_enabled: false,
    is_campaign_creation_cta_enabled: false,
    current_active_designs: [],
    // current_active_designs: ["christmas", "new_year"],
  },
  INSERTS: {
    // 'amazon_5_dollars': 357,
    // 'amazon_10_dollars': 714,
    // 'amazon_15_dollars': 1071,
    // 'amazon_20_dollars': 1428,
    // 'visa_5_dollars': 357,
    // 'visa_10_dollars': 714,
    // 'visa_15_dollars': 1071,
    // 'visa_20_dollars': 1428,
    starbucks_5_dollars: "$5 Starbucks gift card",
    starbucks_10_dollars: "$10 Starbucks gift card",
    starbucks_15_dollars: "$15 Starbucks gift card",
    // 'starbucks_20_dollars': 1428,
  },
  STYLE_LOOKUP: {
    Jane: "5WGWVZ1G00XB", // Autumn
    Ernest: "7EQKAXTR00FR", // Barrow
    Mark: "62AEE4XG00PS", // Selfridge
    Virginia: "8X3WQ2ER00AY", // Thama
    William: "8V2RBTWG008P", // Hampton
    George: "31SB2CWG00DZ", // Whitewell
    Whitman: "5WGWVZ1G00XB", // Autumn,
    Dickinson: "8X3WQ64R00B2", // Irwin,
    Plath: "8X3WQ4D800B0", // Abbey,
    Ginsberg: "5WGWVY9R00WY", // Lafayette,
    Emerson: "8X3WPWHG00AQ", // Dumont,
    Peyton: "8X3WQ4D800B0",
    Easley: "8X3WPWHG00AQ",
    Lytle: "5WGWVWFG00VZ",
    Parker: "5WGWVXNR00WK",
    Brady: "7EQKAXTR00FR",
    Sawdon: "2D5S46JG0004",
    Sutton: "62AEE4XG00PS",
    Tremblay: "31SAZEF000DX",
    Abrahim: "7EQMJ1K800H8",
    Mabus: "5WGWVXG800WG",
    // 'Tonia': '$5' // Handwriting Engine test
  },
  MARGIN: 6, // mm
  MM_IN_PX: 0.0393701,
  GOOD: GOOD,
  TAX_RATE: TAX_RATE,
  EUROPE_VAT_COUNTRIES: EUROPE_VAT_COUNTRIES,
  VOID_GOOD_TAX: [
    // Nothing VOID
    // GOOD.stamp_gb_first_class,
    // GOOD.stamp_gb_second_class,
    // GOOD.stamp_gb_europe,
    // GOOD.stamp_gb_world_zone_1,
    // GOOD.stamp_gb_world_zone_2,
    // GOOD.stamp_us_international,
    // GOOD.stamp_us_first_class
  ],
  SHOPIFY: {
    BASE_ROUTE: "/shopify",
    WEBHOOK_ROUTE: "/webhook",
    INSTALL_ROUTE: "/",
    CALLBACK_ROUTE: "/callback",
    SETTINGS_ROUTE: "/settings",
  },
  HUBSPOT: {
    BASE_ROUTE: "/hubspot",
    WEBHOOK_ROUTE: "/webhook",
    INSTALL_ROUTE: "/install",
    CALLBACK_ROUTE: "/oauth-callback",
    AUTH_ROUTE: "/auth",
    ERROR_ROUTE: "/error",
  },
  REFERRAL_CREDIT: 500,
  RECIPIENT_ERRORS: RECIPIENT_ERRORS,
  CURRENCY_CONVERSION_BASE_URL: "https://openexchangerates.org/api/latest.json",
  EXCHANGE_RATE_EXPIRY_HOURS: 6,
  EXCHANGE_RATE_FALLBACKS: {
    base: "USD",
    rates: {
      EUR: 0.87,
      GBP: 0.73,
      USD: 1,
      CAD: 1.27,
    },
  },
  DELIVERY_GOOD,
  INITIAL_CREDITS_NEW_BILLING: 1000000,
}
