export default {
  paymentMethods: (state) => {
    return state.savedPaymentMethodsList
  },
  customer: (state) => {
    return state.customerData
  },
  getLoadingState: (state) => {
    return state.loading
  },
  getBillingType: (state) => {
    return state.billingType
  },
  getPaymentType: (state) => {
    return state.paymentType
  },
  getNoteCount: (state) => {
    return state.noteCount
  },
  getSelectedCard: (state) => {
    return state.selectedCard
  },
  removeCardResponse: (state) => {
    return state.removeCardResponse
  },
  getMakePaymentDefaultLoading: (state) => {
    return state.makePaymentDefaultLoading
  },
  setDefaultPaymentMethodResponse: (state) => {
    return state.makePaymentDefaultResponse
  },
}
