import api from "../../services/api"

/**
 * The current billing cycles usage
 * @typedef {Object} Usage
 * @property {number} current_amount - The current amount of usage in lowest currency denomination
 * @property {Array<number>} on_demand - On Demand usage array where first item is the free tier
 * @property {number} direct_mail - Direct Mail usage
 * @property {Object} stamps - Stamp usage object where key is the stamp name and value is the usage
 */

// State
/**
 * Module state
 * @typedef {Object} State
 * @property {Usage | null} usage - Usage data
 * @property {boolean} loading - Whether the usage data is being loaded
 * @property {String | null} error - Error message
 */

/**
 * @type {State}
 */
const initialState = {
  loading: false,
  usage: null,
  error: null,
}
/**
 * @type {State}
 */
const state = {
  ...initialState,
}

// Actions
const getUsage = async ({ commit }, user_id) => {
  try {
    commit("startLoading")
    const result = await api.get_usage(user_id)
    commit("updateUsage", result.data)
  } catch (error) {
    console.error(error)
    commit("hasError", "Something went wrong...")
  } finally {
    commit("stopLoading")
  }
}
const actions = {
  getUsage,
}

// Mutations
/**
 * Set the usage data
 * @param {State} state Current state
 * @param {Usage} usage Usage data to update state with
 */
const updateUsage = (state, usage) => {
  state.usage = { ...state.usage, ...usage }
}
const startLoading = (state) => {
  state.loading = true
}
const stopLoading = (state) => {
  state.loading = false
}
const hasError = (state, error) => {
  state.error = error
}
const mutations = {
  updateUsage,
  startLoading,
  stopLoading,
  hasError,
}

// Getters
const getters = {
  usage: (state) => state.usage,
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
}
